import { reset } from "redux-form";
import { NotifError, NotifReactif } from "./notification";
import React, { forwardRef } from "react";
// import compress from "compress-base64";
import Resizer from "react-image-file-resizer";
import moment from "moment-timezone";
import { doDecrypt, doEncrypt } from "./encrypt";
import {
  appVersion,
  lang,
  postData,
  ToastNotification,
  url,
  sendWhatsapp,
  Loading,
  XLSX,
} from "./library";
import QRCode from "qrcode-generator";
import JsBarcode from "jsbarcode";

export const LoadingContent = () => {
  return (
    <Loading
      loading
      background="rgba(0, 0, 0, 0.35)"
      loaderColor="rgba(94, 147, 117, 1)"
    />
  );
};

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return doDecrypt(JSON.parse(c.substring(name.length, c.length)));
    }
  }
  return "";
}
export function setCookie(cname, cvalue) {
  document.cookie = cname + "=" + JSON.stringify(doEncrypt(cvalue)) + ";path=/";
}

export const validateDateRange = (startDateString, endDateString) => {
  const start = new Date(startDateString);
  const end = new Date(endDateString);

  start.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);

  // Memeriksa apakah tanggal awal lebih besar dari tanggal akhir
  if (start > end) {
    // console.log("Tanggal awal tidak boleh lebih besar dari tanggal akhir.");
    return {
      pesan: "Tanggal awal tidak boleh lebih besar dari tanggal akhir",
      status: false,
    };
  }
  // Set tanggal akhir ke akhir hari
  end.setHours(23, 59, 59, 999);

  // Menghitung tanggal 1 bulan setelah tanggal awal
  const minDate = new Date(start);
  minDate.setMonth(start.getMonth() + 1);

  // Memeriksa apakah rentang minimal kurang dari atau sama dengan 1 bulan
  if (end > minDate) {
    // console.log("Rentang tanggal tidak boleh lebih dari 1 bulan.");
    // return false;
    return {
      pesan: "Rentang tanggal tidak boleh lebih dari 1 bulan",
      status: false,
    };
  }

  // Rentang tanggal valid
  return {
    pesan: "",
    status: true,
  };
};

export const generateQRCode = (text) => {
  // Create a QR code instance
  const qr = QRCode(0, "L");

  // Set the data for the QR code
  qr.addData(text);
  qr.make();

  // Create a canvas element and draw the QR code on it
  const canvas = document.createElement("canvas");
  canvas.id = "qrcodeCanvas"; // Unique identifier
  const context = canvas.getContext("2d");
  const cells = qr.getModuleCount();
  const cellSize = 2;

  canvas.width = cells * cellSize;
  canvas.height = cells * cellSize;

  for (let row = 0; row < cells; row++) {
    for (let col = 0; col < cells; col++) {
      context.fillStyle = qr.isDark(row, col) ? "black" : "white";
      context.fillRect(col * cellSize, row * cellSize, cellSize, cellSize);
    }
  }

  return canvas;
};

export const generateBarcode = (text) => {
  const canvas = document.createElement("canvas");
  canvas.id = "barcodeCanvas"; // Unique identifier
  JsBarcode(canvas, text, {
    format: "CODE128",
    displayValue: false,
    width: 2,
    height: 40,
  });
  return canvas;
};

export function removeCookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
}
export const searchAkses = (key, value) => {
  let akses = getItem("userdata")["akses"];
  let level = getItem("userdata")["level"];
  let result = false;
  akses.forEach((x) => {
    if (x[key] === value) {
      result = x.is_show;
    } else {
      x.children.forEach((y) => {
        if (result === false) {
          if (y[key] === value) {
            console.log(y.is_show);
            result = y.is_show;
          } else {
            y.children.forEach((z) => {
              if (result === false) {
                if (z[key] === value) {
                  console.log(y.is_show);
                  result = z.is_show;
                } else {
                  result = false;
                }
              }
            });
          }
        }
      });
    }
  });
  if (level === "SU" || level === "OWN") {
    return true;
  }
  return result;
};

export const setTitle = (path, routeArray, AppName) => {
  var pageTitle;
  for (var i = 0; i < routeArray.length; i++) {
    if (routeArray[i].path === path) {
      pageTitle = AppName + " | " + lang(routeArray[i].title).toUpperCase();
    }
  }
  document.title =
    pageTitle === "undefined | LOGIN"
      ? "LOGIN"
      : pageTitle
      ? pageTitle
      : AppName;
};
export const pembulatanBeratV1 = (weight) => {
  const parameter = 0.05;
  const fixedNumber = Number(
    weight.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]
  );
  const boundary = (parameter * 1000 + fixedNumber * 1000) / 1000;
  const difference = (weight * 1000 - boundary * 1000) / 1000;
  const addParameter =
    difference < 0 ? Math.abs(difference) : parameter - Math.abs(difference);
  return Math.abs(difference) === parameter || difference === 0
    ? weight
    : (weight * 1000 + addParameter * 1000) / 1000;
};
export const pembulatanBeratV2 = (berat) => {
  // pembulatan256
  const toFixedNumber = parseFloat(berat).toFixed(3);
  const toFixedSplited = toFixedNumber.split(".");
  const pointDecimal = Number(toFixedSplited[1].slice(1));
  let baseResult = Number(`${toFixedSplited[0]}.${toFixedSplited[1][0]}`);

  if (pointDecimal > 20 && pointDecimal <= 60) {
    baseResult += 0.05;
  } else if (pointDecimal > 60) {
    baseResult += 0.1;
  }

  return +baseResult.toFixed(3);
};
export const pembulatanBeratV3 = (berat) => {
  // pembulatan258
  const toFixedNumber = parseFloat(berat).toFixed(3);
  const toFixedSplited = toFixedNumber.split(".");
  const pointDecimal = Number(toFixedSplited[1].slice(1));
  let baseResult = Number(`${toFixedSplited[0]}.${toFixedSplited[1][0]}`);

  if (pointDecimal > 20 && pointDecimal <= 80) {
    baseResult += 0.05;
  } else if (pointDecimal > 80) {
    baseResult += 0.1;
  }

  return +baseResult.toFixed(3);
};

export const pembulatanBeratV4 = (berat) => {
  // pembulatan4510
  const toFixedNumber = parseFloat(berat).toFixed(3);
  const toFixedSplited = toFixedNumber.split(".");
  const pointDecimal = Number(toFixedSplited[1].slice(1));
  let baseResult = Number(`${toFixedSplited[0]}.${toFixedSplited[1][0]}`);

  if (pointDecimal > 40 && pointDecimal <= 80) {
    baseResult += 0.05;
  } else if (pointDecimal > 80) {
    baseResult += 0.1;
  }

  return +baseResult.toFixed(3);
};

export const pembulatanBeratV5 = (berat) => {
  // pembulatan257
  const toFixedNumber = parseFloat(berat).toFixed(3);
  const toFixedSplited = toFixedNumber.split(".");
  const pointDecimal = Number(toFixedSplited[1].slice(1));
  let baseResult = Number(`${toFixedSplited[0]}.${toFixedSplited[1][0]}`);

  if (pointDecimal > 20 && pointDecimal <= 70) {
    baseResult += 0.05;
  } else if (pointDecimal >= 71) {
    baseResult += 0.1;
  }

  return +baseResult.toFixed(3);
};

export const pembulatanBerat05Keatas = (berat) => {
  const toFixedNumber = parseFloat(berat).toFixed(3);
  const toFixedSplited = toFixedNumber.split(".");
  const pointDecimal = Number(toFixedSplited[1]);
  let baseResult = Number(`${toFixedSplited[0]}`);
  if (pointDecimal > 0 && pointDecimal <= 500) {
    baseResult += 0.5;
  } else if (pointDecimal >= 500) {
    baseResult += 1;
  } else {
    baseResult += 0.5;
  }
  console.log(baseResult, "PEMBULATAN");
  return +baseResult.toFixed(3);
};

export const pembulatanBeratV6 = (berat) => {
  // pembulatan358
  const toFixedNumber = parseFloat(berat).toFixed(3);
  const toFixedSplited = toFixedNumber.split(".");
  const pointDecimal = Number(toFixedSplited[1].slice(1));
  let baseResult = Number(`${toFixedSplited[0]}.${toFixedSplited[1][0]}`);

  if (pointDecimal === 0) {
    baseResult += 0.0;
  } else if (pointDecimal > 9 && pointDecimal <= 30) {
    baseResult += 0.03;
  } else if (pointDecimal >= 40 && pointDecimal <= 70) {
    baseResult += 0.05;
  } else if (pointDecimal >= 80 && pointDecimal < 90) {
    baseResult += 0.08;
  } else if (pointDecimal >= 90) {
    baseResult += 0.1;
  }

  return +baseResult.toFixed(3);
};

export const capitalize = (str) => {
  return str.replace(/\b\w+\b/g, (c) => c.charAt(0).toUpperCase() + c.slice(1));
};

export const convertParamsToObject = (url) => {
  // Split the query string into parameters and values.
  const params = url.split("?")[1].split("&");

  // Create an object to store the parameters and values.
  const paramsObj = {};

  // Iterate over the parameters and values, adding each pair to the object.
  for (const param of params) {
    const [key, value] = param.split("=");
    paramsObj[key] = value;
  }

  return paramsObj;
};

export const ganerateNotaSlipBaru = (res, form) => {
  let notaBeli = findModule("PRINT_NOTA_BELI_DUPLICATE_MODULE");
  let notaHutang = findModule("PRINT_NOTA_HUTANG_DUPLICATE_MODULE");
  let notaService = findModule("PRINT_NOTA_SERVICE_DUPLICATE_MODULE");
  let notaPesanan = findModule("PRINT_NOTA_PESANAN_DUPLICATE_MODULE");
  let notaTitipan = findModule("PRINT_NOTA_TITIP_DUPLICATE_MODULE");
  let notaAcc = findModule("PRINT_NOTA_JUAL_ACC_DUPLICATE_MODULE");
  let notaServiceAmbil = findModule("PRINT_NOTA_JUAL_ACC_DUPLICATE_MODULE");
  let notaTukar = findModule("REPRINT_SLIP_TUKAR_DUPLICATE");
  // let jml = dupliucateModule ? 2 : 1;
  let jml = 1;
  if (form === "SLIP_PEMBELIAN") {
    if (notaBeli) {
      jml = 2;
    }
  } else if (form === "SLIP_HUTANG") {
    if (notaHutang) {
      jml = 2;
    }
  } else if (form === "SLIP_PENJUALAN_ACC") {
    if (notaAcc) {
      jml = 2;
    }
  } else if (form === "SLIP_SERVICE") {
    if (notaService) {
      jml = 2;
    }
  } else if (form === "SLIP_PESANAN") {
    if (notaPesanan) {
      jml = 2;
    }
  } else if (form === "SLIP_TITIPAN") {
    if (notaTitipan) {
      jml = 2;
    }
  } else if (form === "SLIP_SERVICE_AMBIL") {
    if (notaServiceAmbil) {
      jml = 2;
    }
  } else if (form === "SLIP_TUKAR") {
    if (notaTukar) {
      jml = 2;
    }
  }

  const notaGenerated = [];
  for (let index = 0; index < jml; index++) {
    const regexp = /!!LOOP\(.+\)\{\n((\{[\w\d]+\}|[\w\d :]+)+\n+)+}/gm;
    const regexpContent = /\{[\w\d]+\}/gm;
    let nota = res.data.map((load) => {
      let replaceLoop = res.template;

      let detailsBarang = replaceLoop.replace(regexp, (c) => {
        let tmpRepleacLoop = "";
        load.detail_barang.forEach((detail) => {
          if (c.match(regexpContent)?.[0]) {
            c = c.replace(regexpContent, (d, key) => {
              const keyRemoveCurly = d.replace(/\{|\}/gm, "");

              if (d.match(/nama_barang/)) {
                let keyCustomer = d.match(/nama_barang/);

                let sliceNamaBarang =
                  keyRemoveCurly === "nama_barang2"
                    ? [20, 40]
                    : keyRemoveCurly === "nama_barang3"
                    ? [40, 60]
                    : [0, 20];

                return detail[keyCustomer]?.slice(...sliceNamaBarang).trim();
              }
              if (d.match(/deskripsi_jual/)) {
                let keyCustomer = d.match(/deskripsi_jual/);
                let sliceNamaBarang =
                  keyRemoveCurly === "deskripsi_jual2"
                    ? [20, 40]
                    : keyRemoveCurly === "deskripsi_jual3"
                    ? [40, 60]
                    : [0, 20];

                // return detail[keyCustomer]?.slice(...sliceNamaBarang);
                return detail[keyCustomer]?.slice(...sliceNamaBarang);
              }
              if (d.match(/deskripsi/)) {
                let keyCustomer = d.match(/deskripsi/);
                let sliceNamaBarang =
                  keyRemoveCurly === "deskripsi2"
                    ? [20, 40]
                    : keyRemoveCurly === "deskripsi3"
                    ? [40, 60]
                    : [0, 20];

                return detail[keyCustomer]?.slice(...sliceNamaBarang).trim();
              }
              return detail[d.slice(1, -1)];
            });
          }
          c = c
            .replace(/(!!LOOP\(.+\)\{|\}$)/gm, "")
            .replace(/^\n/, "")
            .trim();
          tmpRepleacLoop += c;
          return c.trim();
        });

        return tmpRepleacLoop;
      });

      return detailsBarang.replace(regexpContent, (d) => {
        const key = d.replace(/(\{|\})/g, "");

        // console.log(d);
        // console.log(load[key]);

        if (key.match(/nama_customer/)) {
          let keyCustomer = key.match(/nama_customer/);
          let sliceNama =
            key === "nama_customer2"
              ? [20, 40]
              : key === "nama_customer3"
              ? [40, 60]
              : [0, 20];
          return load[keyCustomer]?.slice(...sliceNama).trim();
        }
        if (key.match(/alamat_customer/)) {
          let keyCustomer = key.match(/alamat_customer/);
          let sliceNama =
            key === "alamat_customer2"
              ? [20, 40]
              : key === "alamat_customer3"
              ? [40, 60]
              : [0, 20];
          return load[keyCustomer]?.slice(...sliceNama).trim();
        }
        if (key === "intenal_external") {
          return index === 0 ? "Pelanggan" : "Internal";
        }
        if (key === "syarat_ketentuan") {
          return index === 1
            ? ""
            : "Kondisi / Syarat Perjanjian Jual Beli\n-------------------------------------\n1.Penjual menyatakan barang tersebut \nadalah miliknya yang sah dan tidak da\nlam sengketa dengan pihak manapun.\n\n2. Pembeli memberikan tolenransi sel\nama-lamanya 3 bulan sejak perjanjian \nini untuk penjual membeli kembali \nbarang tersebut\n\n3. Penjual menyatakan apabila setelah \nlewat dari 3 bulan tidak membeli kemb-\nali  barang tersebut, berarti telah \nmenjual  secara sah dan memberikan hak \npenuh kepada pembeli atas barang terse-\nbut, serta membebaskan pembeli dari se-\ngala resiko gugatan atas barang tersebut.\n\n4.Penjual dan pembeli mengikatkan diri \npada perjanjian ini tanpa adanya paksa-\nan dari pihak manapun.";
        }
        if (key.match(/auto_cut/)) {
          return "\nVA";
        }

        return load[d.slice(1, -1)];
      });
    });

    for (const key in nota) {
      nota[key] += "\n\n";
    }
    notaGenerated.push(...nota);
  }

  return notaGenerated;
};
export const ganerateNotaSlipLama = (res, form) => {
  let notaBeli = findModule("PRINT_NOTA_BELI_DUPLICATE_MODULE");
  let notaHutang = findModule("PRINT_NOTA_HUTANG_DUPLICATE_MODULE");
  let notaService = findModule("PRINT_NOTA_SERVICE_DUPLICATE_MODULE");
  let notaPesanan = findModule("PRINT_NOTA_PESANAN_DUPLICATE_MODULE");
  let notaTitipan = findModule("PRINT_NOTA_TITIP_DUPLICATE_MODULE");
  let notaAcc = findModule("PRINT_NOTA_JUAL_ACC_DUPLICATE_MODULE");
  let notaServiceAmbil = findModule("PRINT_NOTA_JUAL_ACC_DUPLICATE_MODULE");
  let notaTukar = findModule("REPRINT_SLIP_TUKAR_DUPLICATE");
  // let jml = dupliucateModule ? 2 : 1;
  let jml = 1;
  if (form === "SLIP_PEMBELIAN") {
    if (notaBeli) {
      jml = 2;
    }
  } else if (form === "SLIP_HUTANG") {
    if (notaHutang) {
      jml = 2;
    }
  } else if (form === "SLIP_PENJUALAN_ACC") {
    if (notaAcc) {
      jml = 2;
    }
  } else if (form === "SLIP_SERVICE") {
    if (notaService) {
      jml = 2;
    }
  } else if (form === "SLIP_PESANAN") {
    if (notaPesanan) {
      jml = 2;
    }
  } else if (form === "SLIP_TITIPAN") {
    if (notaTitipan) {
      jml = 2;
    }
  } else if (form === "SLIP_SERVICE_AMBIL") {
    if (notaServiceAmbil) {
      jml = 2;
    }
  } else if (form === "SLIP_TUKAR") {
    if (notaTukar) {
      jml = 2;
    }
  }

  const notaGenerated = [];
  for (let index = 0; index < jml; index++) {
    let nota = res.data.map((load) => {
      let replaceLoop = res.template;
      const regexpContent = /\{[\w\d]+\}/gm;

      while (
        /!!LOOP\((.+)\)\{\n((\{[\w\d]+\}|[\w\d :]+)+\n+)+}/gm.exec(replaceLoop)
      ) {
        replaceLoop = replaceLoop.replace(
          /!!LOOP\((.+)\)\{\n((\{[\w\d]+\}|[\w\d :]+)+\n+)+}/gm,
          (match, key, p2) => {
            let loopArray = !Array.isArray(load[key]) ? [load[key]] : load[key];
            match = loopArray.reduce((acc, val) => {
              let processMatch = match;
              if (processMatch.match(regexpContent)?.[0]) {
                processMatch = processMatch.replace(regexpContent, (c) => {
                  const keyRemoveCurly = c.replace(/\{|\}/gm, "");
                  if (c.match(/nama_barang/)) {
                    let keyCustomer = c.match(/nama_barang/)[0];
                    let sliceNamaBarang =
                      keyRemoveCurly === "nama_barang2"
                        ? [20, 40]
                        : keyRemoveCurly === "nama_barang3"
                        ? [40, 60]
                        : [0, 20];
                    return val[keyCustomer]?.slice(...sliceNamaBarang).trim();
                  }
                  if (c.match(/deskripsi_jual/)) {
                    let keyCustomer = c.match(/deskripsi_jual/)[0];
                    let sliceNamaBarang =
                      keyRemoveCurly === "deskripsi_jual2"
                        ? [20, 40]
                        : keyRemoveCurly === "deskripsi_jual3"
                        ? [40, 60]
                        : [0, 20];

                    return val[keyCustomer]?.slice(...sliceNamaBarang).trim();
                  }
                  if (c.match(/deskripsi/)) {
                    let keyCustomer = c.match(/deskripsi/)[0];
                    let sliceNamaBarang =
                      keyRemoveCurly === "deskripsi2"
                        ? [20, 40]
                        : keyRemoveCurly === "deskripsi3"
                        ? [40, 60]
                        : [0, 20];

                    return val[keyCustomer]?.slice(...sliceNamaBarang).trim();
                  }

                  return val[c.slice(1, -1)];
                });
              }
              processMatch = processMatch.replace(
                /(!!LOOP\(.+\)\{|\}$)/gm,
                "\n\n"
              );

              return acc + processMatch;
            }, "\n\n");

            return match;
          }
        );
      }
      return replaceLoop
        .replace(/\{([a-z0-9_]+)\}/gm, (c) => {
          const key = c.replace(/(\{|\})/g, "");
          if (key === "intenal_external") {
            return index === 0 ? "Pelanggan" : "Internal";
          }
          if (key === "syarat_ketentuan") {
            return index === 1
              ? ""
              : "Kondisi / Syarat Perjanjian Jual Beli\n-------------------------------------\n1.Penjual menyatakan barang tersebut \nadalah miliknya yang sah dan tidak da\nlam sengketa dengan pihak manapun.\n\n2. Pembeli memberikan tolenransi sel\nama-lamanya 3 bulan sejak perjanjian \nini untuk penjual membeli kembali \nbarang tersebut\n\n3. Penjual menyatakan apabila setelah \nlewat dari 3 bulan tidak membeli kemb-\nali  barang tersebut, berarti telah \nmenjual  secara sah dan memberikan hak \npenuh kepada pembeli atas barang terse-\nbut, serta membebaskan pembeli dari se-\ngala resiko gugatan atas barang tersebut.\n\n4.Penjual dan pembeli mengikatkan diri \npada perjanjian ini tanpa adanya paksa-\nan dari pihak manapun.";
          }
          if (key.match(/deskripsi_jual/)) {
            let keyDeskripsi = key.match(/deskripsi_jual/);
            let sliceDeskripsi =
              key === "deskripsi_jual2"
                ? [20, 40]
                : key === "deskripsi_jual3"
                ? [40, 60]
                : [0, 20];
            return load[keyDeskripsi]?.slice(...sliceDeskripsi).trim();
          }
          if (key.match(/nama_customer/)) {
            let keyCustomer = key.match(/nama_customer/);
            let sliceNama =
              key === "nama_customer2"
                ? [20, 40]
                : key === "nama_customer3"
                ? [40, 60]
                : [0, 20];
            return load[keyCustomer]?.slice(...sliceNama).trim();
          }
          if (key.match(/alamat_customer/)) {
            let keyCustomer = key.match(/alamat_customer/);
            let sliceNama =
              key === "alamat_customer2"
                ? [20, 40]
                : key === "alamat_customer3"
                ? [40, 60]
                : [0, 20];
            return load[keyCustomer]?.slice(...sliceNama).trim();
          }
          if (key.match(/nama_barang/)) {
            let keyCustomer = key.match(/nama_barang/);
            // console.log("ini key", keyCustomer);
            let sliceNamaBarang =
              key === "nama_barang2"
                ? [20, 40]
                : key === "nama_barang3"
                ? [40, 60]
                : [0, 20];
            return load[keyCustomer]?.slice(...sliceNamaBarang).trim();
          }
          if (key.match(/deskripsi/)) {
            let keyCustomer = key.match(/deskripsi/);
            let sliceNama =
              key === "deskripsi2"
                ? [20, 40]
                : key === "deskripsi3"
                ? [40, 60]
                : [0, 20];
            return load[keyCustomer]?.slice(...sliceNama).trim();
          }
          if (key.match(/auto_cut/)) {
            return "\nVA";
          }
          return load[key];
        })
        .replace(/\n(\s)+\n/gm, "\n")
        .replace(/~new_line~/gm, "\n");
    });
    for (const key in nota) {
      nota[key] += "\n\n";
    }
    notaGenerated.push(...nota);
  }

  return notaGenerated;
};

export const ganerateNotaSlip = (res, form) => {
  let notaBeli = findModule("PRINT_NOTA_BELI_DUPLICATE_MODULE");
  let notaHutang = findModule("PRINT_NOTA_HUTANG_DUPLICATE_MODULE");
  let notaService = findModule("PRINT_NOTA_SERVICE_DUPLICATE_MODULE");
  let notaPesanan = findModule("PRINT_NOTA_PESANAN_DUPLICATE_MODULE");
  let notaTitipan = findModule("PRINT_NOTA_TITIP_DUPLICATE_MODULE");
  let notaAcc = findModule("PRINT_NOTA_JUAL_ACC_DUPLICATE_MODULE");
  let notaServiceAmbil = findModule("PRINT_NOTA_JUAL_ACC_DUPLICATE_MODULE");
  let notaTukar = findModule("REPRINT_SLIP_TUKAR_DUPLICATE");
  // let jml = dupliucateModule ? 2 : 1;
  let jml = 1;
  if (form === "SLIP_PEMBELIAN") {
    if (notaBeli) {
      jml = 2;
    }
  } else if (form === "SLIP_HUTANG") {
    if (notaHutang) {
      jml = 2;
    }
  } else if (form === "SLIP_PENJUALAN_ACC") {
    if (notaAcc) {
      jml = 2;
    }
  } else if (form === "SLIP_SERVICE") {
    if (notaService) {
      jml = 2;
    }
  } else if (form === "SLIP_PESANAN") {
    if (notaPesanan) {
      jml = 2;
    }
  } else if (form === "SLIP_TITIPAN") {
    if (notaTitipan) {
      jml = 2;
    }
  } else if (form === "SLIP_SERVICE_AMBIL") {
    if (notaServiceAmbil) {
      jml = 2;
    }
  } else if (form === "SLIP_TUKAR") {
    if (notaTukar) {
      jml = 2;
    }
  }

  const notaGenerated = [];
  for (let index = 0; index < jml; index++) {
    let nota = res.data.map((load) => {
      let replaceLoop = res.template;

      while (/\n!!LOOP\((.+)\)(\{\n(.*\n)+\})\n/gm.exec(replaceLoop)) {
        replaceLoop = replaceLoop.replace(
          /\n!!LOOP\((.+)\)(\{\n(.*\n)+\})\n/,
          (match, p1, p2) => {
            const loopContent = p2.replace(/^\{/, "").replace(/\}$/, "");
            // const loopArray = load[p1];
            let loopArray = !Array.isArray(load[p1]) ? [load[p1]] : load[p1];
            const detail = loopArray.reduce((acc, val) => {
              return (
                acc +
                loopContent.replace(/\{([a-z0-9_]+)\}/gm, (c) => {
                  const key = c.replace(/(\{|\})/g, "");
                  // console.log('ini key', key)
                  if (key.match(/nama_barang/)) {
                    let keyCustomer = key.match(/nama_barang/);

                    let sliceNamaBarang =
                      key === "nama_barang2"
                        ? [20, 40]
                        : key === "nama_barang3"
                        ? [40, 60]
                        : [0, 20];
                    return val[keyCustomer]?.slice(...sliceNamaBarang).trim();
                  }
                  if (key.match(/deskripsi_jual/)) {
                    let keyDeskripsi = key.match(/deskripsi_jual/);
                    let sliceDeskripsi =
                      key === "deskripsi_jual2"
                        ? [20, 40]
                        : key === "deskripsi_jual3"
                        ? [40, 60]
                        : [0, 20];
                    return val[keyDeskripsi]?.slice(...sliceDeskripsi).trim();
                  }
                  if (key.match(/deskripsi/)) {
                    let keyCustomer = key.match(/deskripsi/);
                    let sliceNama =
                      key === "deskripsi2"
                        ? [20, 40]
                        : key === "deskripsi3"
                        ? [40, 60]
                        : [0, 20];
                    return val[keyCustomer]?.slice(...sliceNama).trim();
                  }
                  return val[key];
                })
              );
            }, "");
            return detail.replace(/\n(\s)+\n/gm, "\n");
          }
        );
      }
      return replaceLoop
        .replace(/\{([a-z0-9_]+)\}/gm, (c) => {
          const key = c.replace(/(\{|\})/g, "");
          if (key === "intenal_external") {
            return index === 0 ? "Pelanggan" : "Internal";
          }
          if (key === "syarat_ketentuan") {
            return index === 1
              ? ""
              : "Kondisi / Syarat Perjanjian Jual Beli\n-------------------------------------\n1.Penjual menyatakan barang tersebut \nadalah miliknya yang sah dan tidak da\nlam sengketa dengan pihak manapun.\n\n2. Pembeli memberikan tolenransi sel\nama-lamanya 3 bulan sejak perjanjian \nini untuk penjual membeli kembali \nbarang tersebut\n\n3. Penjual menyatakan apabila setelah \nlewat dari 3 bulan tidak membeli kemb-\nali  barang tersebut, berarti telah \nmenjual  secara sah dan memberikan hak \npenuh kepada pembeli atas barang terse-\nbut, serta membebaskan pembeli dari se-\ngala resiko gugatan atas barang tersebut.\n\n4.Penjual dan pembeli mengikatkan diri \npada perjanjian ini tanpa adanya paksa-\nan dari pihak manapun.";
          }
          if (key.match(/deskripsi_jual/)) {
            let keyDeskripsi = key.match(/deskripsi_jual/);
            let sliceDeskripsi =
              key === "deskripsi_jual2"
                ? [20, 40]
                : key === "deskripsi_jual3"
                ? [40, 60]
                : [0, 20];
            return load[keyDeskripsi]?.slice(...sliceDeskripsi).trim();
          }
          if (key.match(/nama_customer/)) {
            let keyCustomer = key.match(/nama_customer/);
            let sliceNama =
              key === "nama_customer2"
                ? [20, 40]
                : key === "nama_customer3"
                ? [40, 60]
                : [0, 20];
            return load[keyCustomer]?.slice(...sliceNama).trim();
          }
          if (key.match(/alamat_customer/)) {
            let keyCustomer = key.match(/alamat_customer/);
            let sliceNama =
              key === "alamat_customer2"
                ? [20, 40]
                : key === "alamat_customer3"
                ? [40, 60]
                : [0, 20];
            return load[keyCustomer]?.slice(...sliceNama).trim();
          }
          if (key.match(/nama_barang/)) {
            let keyCustomer = key.match(/nama_barang/);
            console.log("ini key", keyCustomer);
            let sliceNamaBarang =
              key === "nama_barang2"
                ? [20, 40]
                : key === "nama_barang3"
                ? [40, 60]
                : [0, 20];
            return load[keyCustomer]?.slice(...sliceNamaBarang).trim();
          }
          if (key.match(/deskripsi/)) {
            let keyCustomer = key.match(/deskripsi/);
            let sliceNama =
              key === "deskripsi2"
                ? [20, 40]
                : key === "deskripsi3"
                ? [40, 60]
                : [0, 20];
            return load[keyCustomer]?.slice(...sliceNama).trim();
          }
          if (key.match(/auto_cut/)) {
            return "\nVA";
          }
          return load[key];
        })
        .replace(/\n(\s)+\n/gm, "\n")
        .replace(/~new_line~/gm, "\n")
        .replace(/!!LOOP\(detail_barang\)/g, "")
        .replace(/[}{]/g, "");
    });
    for (const key in nota) {
      nota[key] += "\n\n";
    }
    notaGenerated.push(...nota);
  }

  return notaGenerated;
};

export async function sendInvoiceSingle(nofaktur, no_hp, status, transaksi) {
  await postData(`${status}/get/bynota/single`, nofaktur)
    .then(async (res) => {
      let databaru = {
        data: res.data.data,
        template: res.data.template_wa,
      };
      const notaGenerated = ganerateNotaSlip(databaru, "SLIP_PEMBELIAN").join(
        "\n"
      );
      const data = {
        receiver: no_hp.replace(/^0/, "62"),
        message: notaGenerated,
        type: "text",
      };
      const WhatsappModule = findModule("WHATSAPP_MODULE");
      if (WhatsappModule) {
        let sendwa = await sendWhatsapp("chat/send", data);
        if (sendwa.data.success) {
          ToastNotification("success", "Invoice Berhasil Dikirim");
        } else {
          ToastNotification("info", "No Wa Tidak Terdaftar");
        }
      }
    })
    .catch((err) => {
      console.log(err);
      ToastNotification(
        "info",
        err?.response?.data.message || "No Wa Tidak Terdaftar"
      );
    });
}
export async function sendInvoiceWaGrouped(nofaktur, no_hp, status, transaksi) {
  await postData(`${status}/get/bynota`, nofaktur)
    .then(async (res) => {
      // console.log(transaksi?.is_transaction)
      let databaru = {
        data:
          status === "pembelian"
            ? res.data.dataPembelianGrouped
            : res.data.dataJualGrouped,
        template: res.data.template_wa,
      };
      const notaGenerated = ganerateNotaSlip(databaru, "SLIP_PEMBELIAN").join(
        "\n"
      );
      const data = {
        receiver: no_hp.replace(/^0/, "62"),
        message: notaGenerated,
        type: "text",
      };
      const WhatsappModule = findModule("WHATSAPP_MODULE");
      if (WhatsappModule) {
        let sendwa = await sendWhatsapp("chat/send", data);
        if (sendwa.data.success) {
          ToastNotification("success", "Invoice Berhasil Dikirim");
        } else {
          ToastNotification("info", "No Wa Tidak Terdaftar");
        }
      }
    })
    .catch((err) => {
      console.log(err);
      ToastNotification(
        "info",
        err?.response?.data.message || "No Wa Tidak Terdaftar"
      );
    });
}
export const TextFile = (nama_file) => {
  if (isPos()) {
    // eslint-disable-next-line no-undef
    Print.postMessage(document.getElementById("nota_ganerate").value);
    return false;
  }
  const element = document.createElement("a");
  const file = new Blob([document.getElementById("nota_ganerate").value], {
    type: "text/plain;charset=utf-8",
  });
  element.href = URL.createObjectURL(file);
  element.download = nama_file + ".txt";
  document.body.appendChild(element);
  element.click();
};
export const getRandomColor = (type = "Hexa") => {
  if (type === "Hexa") {
    const randomHex = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomHex.padStart(6, "0")}`;
  } else {
    const randomColor = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
      Math.random() * 256
    )}, ${Math.floor(Math.random() * 256)}, 0.25)`;
    return randomColor;
  }
};

export const logout = (userid) => {
  return new Promise(async (resolve, reject) => {
    postData("users/logout", { user_id: userid })
      .then((res) => {
        window.location.href = url;
        localStorage.clear();
        localStorage.setItem("islogin", "false");
        resolve(true);
      })
      .catch((err) => {
        resolve(false);
        // console.log(err)
        // ToastNotification(
        //   "info",
        //   err.response === undefined ? "Gagal Logout" : err.response.data
        // );
        window.location.reload();
        localStorage.clear();
        localStorage.setItem("islogin", "false");
      });
  });
};

export const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  var arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

  var CSV = "";

  //This condition will generate the Label/Header
  if (ShowLabel) {
    let name = "";

    //This loop will extract the label from 1st index of on array
    for (var index in arrData[0]) {
      //Now convert each value to string and comma-seprated
      name += index + ",";
    }

    name = name.slice(0, -1);

    //append Label name with line break
    CSV += name + "\r\n";
  }

  //1st loop is to extract each row
  for (var i = 0; i < arrData.length; i++) {
    let row = "";

    //2nd loop will extract each column and convert it in string comma-seprated
    for (let index in arrData[i]) {
      row += '"' + arrData[i][index] + '",';
    }

    row.slice(0, row.length - 1);

    //add a line break after each row
    CSV += row + "\r\n";
  }

  if (CSV === "") {
    alert("Invalid data");
    return;
  }

  //Generate a file name
  var fileName = "";
  //this will remove the blank-spaces from the title and replace it with an underscore
  fileName += ReportTitle.replace(/ /g, "_");

  //Initialize file format you want csv or xls
  var uri = "data:text/csv;charset=utf-8," + escape(CSV);

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  //this trick will generate a temp <a /> tag
  var link = document.createElement("a");
  link.href = uri;

  //set the visibility hidden so it will not effect on your web-layout
  link.style = "visibility:hidden";
  link.download = fileName + ".csv";

  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const isNumeric = (str) => {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};
export const CustomeInputDate = forwardRef(({ value, onClick }, ref) => (
  <>
    <div className="input-group mb-3">
      <input
        type="text"
        defaultValue={value}
        readOnly
        className="form-control"
        placeholder="Masukkan Tanggal"
        aria-label="Masukkan Tanggal"
        aria-describedby="basic-addon2"
      />
      <div className="input-group-append">
        <span className="input-group-text" id="basic-addon2">
          <i
            onClick={onClick}
            ref={ref}
            className="fa fa-calendar-alt"
            style={{ cursor: "pointer" }}
          ></i>
        </span>
      </div>
    </div>
  </>
));

export const resizeFileCustom = (file, width, height) =>
  new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      "JPEG",
      50,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const dataURLtoFile = (dataurl, filename) => {
  // export const dataURLtoFile = (dataurl, filename) =>
  let arr = dataurl.split(","),
    // mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename + ".jpg", { type: "image/jpg" });
};
export const dataToPdf = (dataurl, filename) => {
  // export const dataURLtoFile = (dataurl, filename) =>
  let arr = dataurl.split(","),
    // mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename + ".pdf", { type: "aplication/pdf" });
};
export const hiddenRecod = (text) => {
  let hasil = "";
  if (Number(text) === 0) {
    hasil = `  `;
  } else {
    hasil = text;
  }
  return hasil;
};

export const getTotalStroage = () => {
  var allStrings = "";
  for (var key in window.localStorage) {
    if (window.localStorage.hasOwnProperty(key)) {
      allStrings += window.localStorage[key];
    }
  }
  return allStrings
    ? 3 + (allStrings.length * 16) / (8 * 1024) + " KB"
    : "Empty (0 KB)";
};
export const setItem = (nama, data) => {
  localStorage.setItem(doEncrypt(nama), JSON.stringify(doEncrypt(data)));
};
export const removeItem = (nama) => {
  localStorage.removeItem(doEncrypt(nama));
};

export const removeDuplicateObject = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const getItem = (nama) => {
  return localStorage.getItem(doEncrypt(nama)) === null
    ? []
    : doDecrypt(JSON?.parse(localStorage.getItem(doEncrypt(nama))) || "");
};
export const getToday = (add = 0) => {
  const date = new Date();
  date.setDate(date.getDate() + add);
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  );
};

export const addOneDay = (date) => {
  const dateCopy = new Date(date);
  dateCopy.setDate(date.getDate() + 1);
  return dateCopy;
};

export const getTglSystem = () => {
  return getUserdata().tgl_system;
};
export function convertDate(tgl, param) {
  return formatDate(moment(new Date(tgl)).format("YYYY-MM-DD"));
}

export const resizeFile = (file) =>
  new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      720,
      1366,
      "JPEG",
      50,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const resizeFileSplashscreen = (file) =>
  new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      1241,
      2348,
      "JPEG",
      50,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const makeID = () => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 16; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export function convertDateNota(tgl) {
  let date = new Date(tgl);
  let bulan = "";
  // eslint-disable-next-line
  switch (date.getMonth()) {
    case 0:
      bulan = "Januari";
      break;
    case 1:
      bulan = "Februari";
      break;
    case 2:
      bulan = "Maret";
      break;
    case 3:
      bulan = "April";
      break;
    case 4:
      bulan = "Mei";
      break;
    case 5:
      bulan = "Juni";
      break;
    case 6:
      bulan = "Juli";
      break;
    case 7:
      bulan = "Agustus";
      break;
    case 8:
      bulan = "September";
      break;
    case 9:
      bulan = "Oktober";
      break;
    case 10:
      bulan = "November";
      break;
    case 11:
      bulan = "Desember";
      break;
  }
  let hasil = date.getDate() + " " + bulan + " " + date.getFullYear();
  return hasil;
}

export function convertDateNew(tgl) {
  let date = new Date(tgl);
  let bulan = "";
  // eslint-disable-next-line
  switch (date.getMonth()) {
    case 0:
      bulan = "/01/";
      break;
    case 1:
      bulan = "/02/";
      break;
    case 2:
      bulan = "/03/";
      break;
    case 3:
      bulan = "/04/";
      break;
    case 4:
      bulan = "/05/";
      break;
    case 5:
      bulan = "/06/";
      break;
    case 6:
      bulan = "/07/";
      break;
    case 7:
      bulan = "/08/";
      break;
    case 8:
      bulan = "/09/";
      break;
    case 9:
      bulan = "/10/";
      break;
    case 10:
      bulan = "/11/";
      break;
    case 11:
      bulan = "/12/";
      break;
  }
  let hasil =
    date.getDate() +
    " " +
    bulan +
    " " +
    date.getFullYear().toString().substr(-2);
  // getFullYear().toString().substr(-2)
  return hasil;
}

export function convertDateTime(tgl) {
  return moment(new Date(tgl), "Asia/Jakarta").format("HH:mm:ss");
}
export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const convertXLSXToJson = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1, // Assume the first row contains headers
      });

      // Convert header keys to lowercase and replace spaces with underscores
      const formattedData = jsonData.map((row) => {
        const formattedRow = {};
        row.forEach((value, index) => {
          const header = jsonData[0][index]; // Assuming the first row contains headers
          const formattedHeader = header.replace(/\s+/g, "_").toLowerCase(); // Convert to lowercase and replace spaces with underscores
          formattedRow[formattedHeader] = value;
        });
        return formattedRow;
      });

      formattedData.shift();

      resolve(formattedData);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};
export const getUserdata = () => {
  return getItem("userdata") || [];
};

export function filterAngka(data) {
  return data === undefined ? 0 : data;
}
export function filtertext(data) {
  return data === undefined ? "-" : data;
}
export const ErrorHandling = (
  err = "",
  endpoint,
  data,
  dispatch,
  table,
  hideModal,
  modalReset
) => {
  return new Promise((resolve, reject) => {
    let response =
      err.response === undefined
        ? "Koneksi Bermasalah"
        : err?.response?.data || "-";
    let check = response?.includes("DELETED");
    check
      ? NotifReactif(err, endpoint, data)
          .then(() => {
            dispatch(table);
          })
          .then(() => {
            dispatch(hideModal);
          })
          .then(() => {
            dispatch(reset(modalReset));
          })
          .then(resolve("berhasil"))
          .catch(reject("error"))
      : NotifError(
          err.response === undefined ? "Koneksi Bermasalah" : err.response.data
        )
          .then(resolve("berhasil"))
          .catch(reject("error"));
  });
};

export default function LoadingButton(props) {
  let className = props.className;
  let text = props.text;
  return (
    <button className={className} type="button">
      {" "}
      <i className="fas fa-spinner fa-spin"></i> {text}
    </button>
  );
}

export const isPos = () => {
  const version = appVersion;
  const ua = navigator.userAgent;

  if (
    version.includes("POS") ||
    (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) &&
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      ))
  ) {
    return true;
  } else {
    return false;
  }
};

export const hexEncode = (str) => {
  var result = "";
  result = str.toString(16);
  return result;
};

export const hexdec = (hex) => {
  var str = "";
  str = parseInt(hex, 16);
  return str;
};
export const chr = (asci) => {
  var str = "";
  str = String.fromCharCode(asci);
  return str;
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatGram = (gram, lenght) => {
  return parseFloat(gram || 0)
    .toFixed(3)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const pembulatan = (harga) => {
  const module = getItem("module") || [];

  let aktif = false;
  if (
    module.findIndex(
      (list) => list.key === "PEMBULATAN_PEMBELIAN_KEBAWAH_MODULE"
    ) === -1
  ) {
    aktif = false;
  } else {
    aktif = true;
  }

  // console.log(getItem("pembulatan").pembulatan)

  const parameter = { value: getItem("pembulatan").pembulatan };
  const harga_calculated = harga;

  const harga_modded = harga_calculated % Number(parameter.value);

  let harga_jual = harga_calculated;

  if (harga_modded !== 0) {
    aktif
      ? (harga_jual = harga_jual - harga_modded)
      : (harga_jual = harga_jual - harga_modded + Number(parameter.value));
  }

  return harga_jual;
};

export const shortByReport = (data, type) => {
  let result = data.sort((a, b) => {
    if (a[type] < b[type]) {
      return -1;
    }
    return 1;
  });

  // console.log(result, type);
  return result;
};

// export const infoMarketPlace = (toko) =>{
//   let datamarketplace = getItem("marketplace_integration").find(
//     (list) => list.k_m === toko
//   );
//   return datamarketplace
// }
export const downloadfileImg = (dataimg, namafile) => {
  var a = document.createElement("a"); //Create <a>
  a.href = dataimg; //Image Base64 Goes here
  a.download = `${namafile}.jpg`; //File name Here
  a.click(); //Downloaded file
};
export const pembulatanPenjualan = (harga) => {
  const module = getItem("module") || [];

  let aktif = false;
  if (
    module.findIndex(
      (list) => list.key === "PEMBULATAN_PENJUALAN_KEBAWAH_MODULE"
    ) === -1
  ) {
    aktif = false;
  } else {
    aktif = true;
  }

  const parameter = { value: getItem("pembulatan").pembulatan };
  const harga_calculated = harga;

  const harga_modded = harga_calculated % Number(parameter.value);

  let harga_jual = harga_calculated;

  if (harga_modded !== 0) {
    aktif
      ? (harga_jual = harga_jual - harga_modded)
      : (harga_jual = harga_jual - harga_modded + Number(parameter.value));
  }

  return harga_jual;
};

export const findModule = (name) => {
  return getItem("module").findIndex((cek) => cek.key === name) === -1
    ? false
    : true;
};
export const findModuleText = (name) => {
  return getItem("module").find((cek) => cek.key === name);
};

export const HideSaldoSummary = () => {
  const moduleHideSaldoBrgSummary = findModule("HIDE_SALDO_BARANG_SUMMARY");
  let statushide = moduleHideSaldoBrgSummary
    ? getUserdata().level === "OWN"
      ? true
      : false
    : true;
  return statushide;
};
export const pembulatanPenjualan500 = (harga) => {
  const parameter = 500;
  const harga_calculated = harga;

  const harga_modded = harga_calculated % Number(parameter);

  let harga_jual = harga_calculated;

  if (harga_modded !== 0) {
    harga_jual = harga_jual - harga_modded + Number(parameter);
  }
  return harga_jual || 0;
};
export const customePembulatan = (harga, nominal) => {
  const parameter = nominal;
  const harga_calculated = harga;

  const harga_modded = harga_calculated % Number(parameter);

  let harga_jual = harga_calculated;

  if (harga_modded !== 0) {
    harga_jual = harga_jual - harga_modded + Number(parameter);
  }
  return harga_jual || 0;
};
export const formaterBerat = (berat) => {
  if (berat < 1000) {
    return parseFloat(berat).toFixed(3);
  } else {
    return Number(parseFloat(berat).toFixed(3)).toLocaleString("kr-ko");
  }
};

export const fomaterBeratNew = (berat) => {
  const tmpBerat = parseFloat(berat || 0);
  const beratTofixed = tmpBerat.toFixed(3).toLocaleString("kr-KO");

  // const replaceangka = beratTofixed.replace(/(?<=\.)\d+$/, (c) =>
  //   c.padEnd(3, "0")
  // );
  const replaceangka = beratTofixed.replace(/\.(\d+)$/, (c) =>
    c.padEnd(3, "0")
  );
  const splitangka =
    replaceangka.split(".")[0].replace(/(\d)(?=(\d{3})+(?!\d))/gm, "$1,") +
    "." +
    replaceangka.split(".")[1];

  return splitangka;
};

export const removeBR = (data) => {
  let dataku = data || "<br/>-";
  let hasilku = dataku.split("<br/>");
  let hasilakhir = "";
  if (hasilku.length > 2) {
    for (let ia = 1; ia < hasilku.length; ia++) {
      if (hasilakhir === "") {
        hasilakhir += hasilku[ia];
      } else {
        hasilakhir += `\n` + hasilku[ia];
      }
    }
  } else {
    hasilakhir = hasilku[1];
  }
  return hasilakhir;
};
