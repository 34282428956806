import Axios from "axios";
import { doEncrypt } from "../components/helpers/encrypt";
import {
  convertBase64,
  dataURLtoFile,
  getUserdata,
  getItem,
  server,
  firebasemame,
  serverOnlineStore,
  serverGrosir,
  serverWa,
  ModePusat,
  resizeFile,
} from "../components/helpers/library";
import { ToastNotification } from "../components/helpers/notification";
import firebase from "../firebase.jsx";

const TOKEN_PUSAT =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiUFVTQVQiLCJsZXZlbCI6IlNVIiwiaWF0IjoxNjQyNDAyMDg4fQ.TWq2eBf_HAtwYzeomz8KA7PJM0151iafJsJjQmz2Zxs";
// 192.168.1.22

// 188.166.254.162
let timeout_putData = "";
let timeout_deleteData = "";

export function getDataParamsPusat(endpoint, params) {
  let config = {
    headers: {
      "x-auth-token": TOKEN_PUSAT,
      "ngrok-skip-browser-warning": 1,
    },
    params: params,
  };
  return new Promise((resolve, reject) => {
    Axios.get(endpoint, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(err?.response?.data);
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            reject(err);
          }
        }
      });
  });
}

export function postPdf(file, name) {
  const toko = getUserdata().kode_toko;
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    let stoageRef = storage.ref(`NSIPIC/${firebasemame || toko}/${name}.pdf`);
    stoageRef
      .put(file)
      .then((res) => {
        stoageRef.getDownloadURL().then(function (url) {
          resolve(url);
        });
      })
      .catch((err) => {
        reject(JSON.parse(err));
      });
  });
}
export function postImage(file, name) {
  const toko = getUserdata().kode_toko;
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    let stoageRef = storage.ref(`NSIPIC/${firebasemame || toko}/${name}.jpg`);
    stoageRef
      .put(file)
      .then((res) => {
        stoageRef.getDownloadURL().then(function (url) {
          resolve(url);
        });
      })
      .catch((err) => {
        reject(JSON.parse(err));
      });
  });
}
export function deleteImage(name) {
  const toko = getUserdata().kode_toko;

  return new Promise((resolve, reject) => {
    let storage = firebase.storage();
    let storageRef = storage.ref();
    let desertRef = storageRef.child(
      `NSIPIC/${firebasemame || toko}/${name}.jpg`
    );
    desertRef
      .delete()
      .then(function () {})
      .catch(function (error) {
        reject(error);
      });
  });
}

export function deleteImageApp(name) {
  const toko = getUserdata().kode_toko;
  return new Promise((resolve, reject) => {
    let storage = firebase.storage();
    let storageRef = storage.ref();
    let desertRef = storageRef.child(
      `NSIPIC/${firebasemame || toko}/${name}.jpg`
    );
    desertRef
      .delete()
      .then(function () {
        resolve(true);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getImageSplashscreenApp(file) {
  const toko = getUserdata().kode_toko;
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    let stoageRef = storage.ref(`NSIPIC/${firebasemame || toko}/` + file);
    stoageRef
      .getDownloadURL()
      .then(function (url) {
        // console.log('ini url', url)
        resolve(url);
      })
      .catch((err) => {
        reject(JSON.parse(err.customData.serverResponse).error);
      });
  });
}

export function postSplashImageApp(file, name) {
  const toko = getUserdata().kode_toko;
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    let stoageRef = storage.ref(
      `NSIPIC/${firebasemame || toko}/` + name + ".png"
    );
    stoageRef
      .put(file)
      .then((res) => {
        stoageRef.getDownloadURL().then(function (url) {
          resolve(url);
        });
      })
      .catch((err) => {
        reject(JSON.parse(err));
      });
  });
}

export function getImageApp(file) {
  // console.log(file);
  const toko = getUserdata().kode_toko;
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    let stoageRef = storage.ref(
      `NSIPIC/${firebasemame || toko}/` + file + ".jpg"
    );
    stoageRef
      .getDownloadURL()
      .then(function (url) {
        // console.log('ini url', url)
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = async function (event) {
          let data = await convertBase64(xhr.response);
          const file = dataURLtoFile(data);
          const res = await resizeFile(file);
          resolve(res);
        };
        xhr.open("GET", url);
        xhr.send();
        // resolve(url);
      })
      .catch((err) => {
        reject(JSON.parse(err.customData.serverResponse).error);
      });
  });
}

export function getImageUrlApp(file) {
  // console.log(file);
  const toko = getUserdata().kode_toko;
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    let stoageRef = storage.ref(
      `NSIPIC/${firebasemame || toko}/` + file + ".jpg"
    );
    stoageRef
      .getDownloadURL()
      .then(function (url) {
        // console.log('ini url', url)
        resolve(url);
      })
      .catch((err) => {
        reject(JSON.parse(err.customData.serverResponse).error);
      });
  });
}

export function postImageApp(file, name) {
  const toko = getUserdata().kode_toko;
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    let stoageRef = storage.ref(
      `NSIPIC/${firebasemame || toko}/` + name + ".jpg"
    );
    stoageRef
      .put(file)
      .then((res) => {
        stoageRef.getDownloadURL().then(function (url) {
          resolve(url);
        });
      })
      .catch((err) => {
        reject(JSON.parse(err));
      });
  });
}

export function getImage(file, status) {
  const toko = getUserdata().kode_toko;
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    let stoageRef = storage.ref(`NSIPIC/${firebasemame || toko}/${file}.jpg`);
    stoageRef
      .getDownloadURL()
      .then(function (url) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = async function (event) {
          let data = await convertBase64(xhr.response);
          const file = dataURLtoFile(data);
          const res = await convertBase64(file);
          if (status) {
            resolve({
              res: res,
              url: url,
            });
          } else {
            resolve(res);
          }
        };
        xhr.onerror = async function (err) {
          // console.log('ERROR',err);
          reject("eror");
        };
        xhr.open("GET", url);
        xhr.send();
        // resolve(url);
      })
      .catch((err) => {
        // console.log(err)
        // console.log(JSON.parse(err.customData.serverResponse).error);
        reject(JSON.parse(err.customData.serverResponse).error);
      });
  });
}

export function getDataNoTimeOut(endpoint) {
  const isPusat =
    ModePusat === "PUSAT"
      ? getItem("portal1").length > 0
        ? true
        : false
      : getItem("portal1").length > 0
      ? true
      : false;
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      "ngrok-skip-browser-warning": 1,
    },
  };

  const portal1 = getItem("portal1");
  // console.log(portal1);
  return new Promise((resolve, reject) => {
    Axios.get(
      portal1.length > 0 ? portal1 + endpoint : server + endpoint,
      config
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(err?.response?.data);
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}

export function getEnpointGrosir(endpoint, ignore = []) {
  const isPusat =
    ModePusat === "PUSAT" && getItem("portal1").length > 0 ? true : false;
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      "ngrok-skip-browser-warning": 1,
    },
  };
  // console.log(serverGrosir);
  return new Promise((resolve, reject) => {
    if (serverGrosir === "#") {
      reject("Server Bacnkend grosir belum di setting");
      return false;
    }
    Axios.get(serverGrosir + endpoint, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          // window.location.reload();
          // localStorage.clear();
          // localStorage.setItem("islogin", "false");
          reject("Invalid Token For Grosir Program");
        } else {
          reject(err);
        }
      });
  });
}
export function postDataGrosir(endpoint, data) {
  const isPusat =
    ModePusat === "PUSAT" && getItem("portal1").length > 0 ? true : false;
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      "ngrok-skip-browser-warning": 1,
    },
  };
  return new Promise((resolve, reject) => {
    Axios.post(serverGrosir + endpoint, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}

// export function getMarketplace(endpoint, params, toko) {
//   let server =
//     toko === "TOKOPEDIA" ? process.env.REACT_APP_BACKEND_TOKOPEDIA : "";
//   let datamarketplace = getItem("marketplace_integration").find(
//     (list) => list.k_m === toko
//   );

//   let config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + datamarketplace.b_t,
//       "Access-Control-Allow-Origin": "*",
//     },
//     params: params
//   };

//   return new Promise((resolve, reject) => {
//     Axios.get(server + endpoint, config)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         if (Axios.isCancel(err)) {
//           reject(err);
//         } else {
//           var hasil = /Invalid Token./i.test(err?.response?.data);
//           if (hasil) {
//             window.location.reload();
//             localStorage.clear();

//           } else {
//             reject(err);
//           }
//         }
//       });
//   });
// }
export function getData(endpoint, ignore = [], encrypt = false) {
  const isPusat =
    ModePusat === "PUSAT" && getItem("portal1").length > 0 ? true : false;
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      enc: encrypt ? 1 : 0,
      ignore: JSON.stringify(ignore),
      "ngrok-skip-browser-warning": 1,
    },
    baseURL: server,
  };
  const portal1 = getItem("portal1");
  return new Promise((resolve, reject) => {
    Axios.get(
      portal1.length > 0 ? portal1 + endpoint : server + endpoint,
      config
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(err?.response?.data);
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            reject(err);
          }
        }
      });
  });
}
export function getDataParams(endpoint, params) {
  const isPusat =
    ModePusat === "PUSAT" && getItem("portal1").length > 0 ? true : false;
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      "ngrok-skip-browser-warning": 1,
    },
    params: params,
  };
  const portal1 = getItem("portal1");
  return new Promise((resolve, reject) => {
    Axios.get(
      portal1.length > 0 ? portal1 + endpoint : server + endpoint,
      config
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(err?.response?.data);
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            reject(err);
          }
        }
      });
  });
}

export function getDataMultipleWithParams(baseurl, params) {
  const isPusat =
    ModePusat === "PUSAT" && getItem("portal1").length > 0 ? true : false;
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      "ngrok-skip-browser-warning": 1,
    },
    params: params,
  };
  return new Promise((resolve, reject) => {
    Axios.get(baseurl, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            reject(err);
          }
        }
      });
  });
}

export async function getDatatPusat(
  endpoint,
  data,
  ignore = [],
  encrypt = false
) {
  const isPusat =
    ModePusat === "PUSAT" && getItem("portal1").length > 0 ? true : false;
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      enc: encrypt ? 1 : 0,
      ignore: JSON.stringify(ignore),
      "ngrok-skip-browser-warning": 1,
    },
  };
  const portal1 = getItem("portalPusat");
  let portalSemua = portal1.split(",");
  let feeback = [];
  for (let index = 0; index < portalSemua.length; index++) {
    if (portalSemua[index] !== "https://hidup.com") {
      try {
        let row = await Axios.get(
          portalSemua[index] + "/api/v1/" + endpoint,
          encrypt ? doEncrypt(data, ignore) : data,
          config
        );
        feeback.push(row);
      } catch (err) {
        let row = {
          status: "error",
          message: "Data Gagal Dimuat",
          data: [],
        };
        feeback = row;
      }
    }
  }

  return new Promise((resolve, reject) => {
    resolve(feeback);
  });
}
export async function postDataNoTImeOutPusat(
  endpoint,
  data,
  ignore = [],
  encrypt = false
) {
  const isPusat =
    ModePusat === "PUSAT" && getItem("portal1").length > 0 ? true : false;
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      enc: encrypt ? 1 : 0,
      ignore: JSON.stringify(ignore),
      "ngrok-skip-browser-warning": 1,
    },
  };
  const portal1 = getItem("portalPusat");
  let portalSemua = portal1.split(",");
  let feeback = [];
  for (let index = 0; index < portalSemua.length; index++) {
    try {
      let row = await Axios.post(
        portalSemua[index] + "/api/v1/" + endpoint,
        encrypt ? doEncrypt(data, ignore) : data,
        config
      );
      feeback.push(row);
    } catch (err) {
      let row = {
        status: "error",
        message: "Data Gagal Dimuat",
      };
      feeback = row;
    }
  }

  return new Promise((resolve, reject) => {
    resolve(feeback);
  });
}
export function postDataNoTImeOut(
  endpoint,
  data,
  ignore = [],
  encrypt = false
) {
  const isPusat =
    ModePusat === "PUSAT" && getItem("portal1").length > 0 ? true : false;
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      enc: encrypt ? 1 : 0,
      ignore: JSON.stringify(ignore),
      "ngrok-skip-browser-warning": 1,
    },
  };
  const portal1 = getItem("portal1");
  return new Promise((resolve, reject) => {
    Axios.post(
      portal1.length > 0 ? portal1 + endpoint : server + endpoint,
      encrypt ? doEncrypt(data, ignore) : data,
      config
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        let hasil = /Invalid Token./i.test(err?.response?.data);
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}

export function postUrlOnlineStore(
  endpoint,
  data,
  ignore = [],
  encrypt = false
) {
  let config = {
    headers: {
      "x-auth-token": getItem("userdata").token,
      enc: encrypt ? 1 : 0,
      ignore: JSON.stringify(ignore),
      "ngrok-skip-browser-warning": 1,
    },
  };
  return new Promise((resolve, reject) => {
    Axios.post(
      serverOnlineStore + endpoint,
      encrypt ? doEncrypt(data, ignore) : data,
      config
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}

export function putUrlOnlineStore(
  endpoint,
  data,
  ignore = [],
  encrypt = false
) {
  let config = {
    headers: {
      "x-auth-token": getItem("userdata").token,
      enc: encrypt ? 1 : 0,
      ignore: JSON.stringify(ignore),
      "ngrok-skip-browser-warning": 1,
    },
  };
  return new Promise((resolve, reject) => {
    Axios.put(
      serverOnlineStore + endpoint,
      encrypt ? doEncrypt(data, ignore) : data,
      config
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}

export function sendAuthNotification(menu) {
  let config = {
    headers: {
      Authorization:
        "key=AAAAoc_NQwU:APA91bHu5AvxTNoBlQmr8rH4xyWi066wKZ8bK9ooBztT27qnQ3ahXE6KRHvLjKALX_vCjEb9aKXzklqZeUYyt6_D_bm7lN6UbPbGuW2-2y7UVNrSj40sbZWabPSYVhZzIFzXFiXP-VAl",
    },
  };
  const data = {
    to: "/topics/tokomas-octa",
    mutable_content: false,
    content_available: true,
    priority: "high",
    data: {
      content: {
        id: 100,
        channelKey: "nagatech_channel",
        title: `Authenticate Needed from Octavian`,
        body: `Octavian Need Authentication for ${menu}`,
        notificationLayout: "BigText",
        showWhen: true,
        autoDismissible: true,
      },
    },
  };

  return new Promise((resolve, reject) => {
    Axios.post("https://fcm.googleapis.com/fcm/send", data, config)
      .then((res) => {
        console.log(res.data);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function putDataNoTImeOut(endpoint, data, ignore = [], encrypt = false) {
  const isPusat =
    ModePusat === "PUSAT" && getItem("portal1").length > 0 ? true : false;
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      enc: encrypt ? 1 : 0,
      ignore: JSON.stringify(ignore),
      "ngrok-skip-browser-warning": 1,
    },
  };

  const portal1 = getItem("portal1");
  return new Promise((resolve, reject) => {
    Axios.put(
      portal1.length > 0 ? portal1 + endpoint : server + endpoint,
      encrypt ? doEncrypt(data, ignore) : data,
      config
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}

export function postData(endpoint, data, ignore = [], encrypt = false) {
  const isPusat =
    ModePusat === "PUSAT" && getItem("portal1").length > 0 ? true : false;
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      enc: encrypt ? 1 : 0,
      ignore: JSON.stringify(ignore),
      "ngrok-skip-browser-warning": 1,
    },
  };

  const portal1 = getItem("portal1");
  return new Promise((resolve, reject) => {
    Axios.post(
      portal1.length > 0 ? portal1 + endpoint : server + endpoint,
      encrypt ? doEncrypt(data, ignore) : data,
      config
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            reject(err);
          }
        }
      });
  });
}

export function deleteData(endpoint, data) {
  clearTimeout(timeout_deleteData);
  const isPusat =
    ModePusat === "PUSAT" && getItem("portal1").length > 0 ? true : false;
  let hasil = {
    data: data,
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      "ngrok-skip-browser-warning": 1,
    },
  };
  const portal1 = getItem("portal1");
  return new Promise((resolve, reject) => {
    Axios.delete(
      portal1.length > 0 ? portal1 + endpoint : server + endpoint,
      hasil
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            reject(err);
          }
        }
      });
  });
}
export function putData(endpoint, data, ignore = [], encrypt = false) {
  clearTimeout(timeout_putData);
  const isPusat =
    ModePusat === "PUSAT" && getItem("portal1").length > 0 ? true : false;
  // localStorage.setItem("daaa", JSON.stringify(data));
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      enc: encrypt ? 1 : 0,
      ignore: JSON.stringify(ignore),
      "ngrok-skip-browser-warning": 1,
    },
  };

  const portal1 = getItem("portal1");
  return new Promise((resolve, reject) => {
    Axios.put(
      portal1.length > 0 ? portal1 + endpoint : server + endpoint,
      encrypt ? doEncrypt(data, ignore) : data,
      config
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            reject(err);
          }
        }
      });
  });
}

export function checkTimeout(err) {
  if (new RegExp("timeout", "g").test(err)) {
    ToastNotification("info", "Koneksi bermasalah..");
    return false;
  } else {
    return true;
  }
}

export function sendWhatsapp(endpoint, data, ignore = [], encrypt = false) {
  let config = {
    params: {
      id: getUserdata().no_whatsapp,
    },
  };
  return new Promise((resolve, reject) => {
    Axios.post(serverWa + endpoint, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const uploadFileShopee = (endpoint, data) => {
  const isPusat =
    ModePusat === "PUSAT" && getItem("portal1").length > 0 ? true : false;
  let config = {
    headers: {
      Accept: "application/json",
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      "Content-Type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("image", data.image);
  formData.append("kode_barcode", data.kode_barcode);
  console.log(data);
  return new Promise((resolve, reject) => {
    Axios.post(server + endpoint, formData, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function customeGetAxios(endpoint, params) {
  const isPusat =
    ModePusat === "PUSAT" && getItem("portal1").length > 0 ? true : false;
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      "ngrok-skip-browser-warning": 1,
    },
    params: params,
  };
  return new Promise((resolve, reject) => {
    Axios.get(endpoint, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(err?.response?.data);
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            reject(err);
          }
        }
      });
  });
}

export function customPostAxios(endpoint, data, ignore = [], encrypt = false) {
  const isPusat = ModePusat === "PUSAT" ? true : false;
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      enc: encrypt ? 1 : 0,
      ignore: JSON.stringify(ignore),
    },
  };

  return new Promise((resolve, reject) => {
    Axios.post(endpoint, encrypt ? doEncrypt(data, ignore) : data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}
export function customPutAxios(endpoint, data, ignore = [], encrypt = false) {
  const isPusat = ModePusat === "PUSAT" ? true : false;
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
      enc: encrypt ? 1 : 0,
      ignore: JSON.stringify(ignore),
    },
  };

  return new Promise((resolve, reject) => {
    Axios.put(endpoint, encrypt ? doEncrypt(data, ignore) : data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}
export function customDeleteAxios(endpoint, data) {
  const isPusat = ModePusat === "PUSAT" ? true : false;
  let config = {
    headers: {
      "x-auth-token": isPusat ? TOKEN_PUSAT : getItem("userdata").token,
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    Axios.delete(endpoint, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}
