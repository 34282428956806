import { ToastNotification } from "../components/helpers/library";

export const DisableAllType = (value, previousValue) => {
  if (value) {
    return parseInt(value.replace(/[^]/g, 0));
  } else {
    return value;
  }
};

export const Gramasi = (value, previousValue) => {
  if (value) {
    return value.replace(/[^0-9.]+/g, "");
  } else {
    return value;
  }
};

export const NormalizeTimbangan = (value) => {
  var status = localStorage.getItem("is_timbang") || "true";
  var timbangan = localStorage.getItem("timbangan-active") || "false";
  console.log(status);
  if (status === "true") {
    if (timbangan === "true") {
      return Gramasi(value);
    } else {
      return DisableAllType(value);
    }
  } else {
    return Gramasi(value);
  }
};

export const Kadar = (value, previousValue) => {
  if (value) {
    return value.replace(/[^0-9/.K/]+/g, "");
  } else {
    return value;
  }
};

export const KadarJual = (value, previousValue) => {
  if (value) {
    if (value <= 100) {
      return value.replace(/[^0-9/.K/]+/g, "");
    } else {
      ToastNotification("error", "Kadar Tidak Boleh Melebihi 100 !");
      return 0;
    }
  } else {
    return value;
  }
};

export const NumberOnly = (value, previousValue) => {
  if (value) {
    return value.replace(/[^\d]/g, "");
  } else {
    return value;
  }
};
export const CekInputLebihDari1 = (value, previousValue) => {
  if (Number(value) < 1) {
    return "";
  } else {
    return value.replace(/[^\d]/g, "");
  }
};
export const BatasDuaAngkaDibelakangKoma = (value, previousValue) => {
  const regex = /^\d*\.?\d{0,2}$/; // regex untuk memvalidasi dua angka di belakang koma
  const isValidInput = regex.test(value);
  if (!isValidInput) {
    return value.slice(0, -1); // menghapus karakter terakhir dari input jika tidak valid
  } else {
    return value;
  }
};

export const ReplaceValue = (value) => {
  if (value) {
    return value.replace(" ", "_");
  } else {
    return value;
  }
};

export const ValidasiPersen = (value, previousValue) => {
  if (value) {
    if (value <= 100) {
      return value.replace(/[^0-9/.K/]+/g, "");
    } else {
      ToastNotification("error", "Tidak Boleh Melebihi 100 !");
      return "";
    }
  } else {
    return value;
  }
};
