import {
  React,
  getItem,
  findModule,
  getUserdata,
  findModuleText,
} from "../components/helpers/library";
import {
  ListKodeTokoBerkah,
  ListTokoLaris,
} from "../components/helpers/ListKodeToko";

import {
  LaporanPembelianPerfaktur,
  LaporanPenebusanDetail,
} from "../pages/Laporan/LaporanHidup";
import {
  BatalCicilan,
  LihatCicilan,
  ParameterCicilan,
  TransaksiCicilan,
  LelangCicilan,
} from "../pages/Cicilan";
import { ParameterAkun } from "../pages/Akuntansi";
import { lazy } from "react";
import FormLaporanAnalisisHargaBarang from "../pages/Laporan/LaporanBarang/LaporanAnalisiHargaBarang/FormLaporanAnalisisHargaBarang.jsx";
import MutasiUangBank from "../pages/UangKas/MutasiUangBank/index.jsx";
import MutasiUangCash from "../pages/UangKas/MutasiUangCash/index.jsx";
import LaporanKelompokBarang from "../pages/Laporan/LaporanBarang/LaporanKelompokBarang/index.jsx";
import ParameterHargaBeli from "../pages/DataMaster/ParameterHargaBeli/index.jsx";
import GantiPassword from "../pages/utility/GantiPassword/index.jsx";
import KategoriKatalog from "../pages/KatalogApp/KategoriKatalog/index.jsx";
import HargaKatalog from "../pages/KatalogApp/HargaKatalog/index.jsx";
import BannerKatalog from "../pages/KatalogApp/BannerKatalog/index.jsx";
import InfoKatalog from "../pages/KatalogApp/InfoKatalog/index.jsx";
import BarangKatalog from "../pages/KatalogApp/BarangKatalog/index.jsx";
import KontakAdminKatalog from "../pages/KatalogApp/KontakAdminKatalog/index.jsx";
import HadiahKatalog from "../pages/KatalogApp/HadiahKatalog/index.jsx";
import GroupDescriptionKatalog from "../pages/KatalogApp/GroupDescriptionKatalog/index.jsx";
import SplashscreenKatalog from "../pages/KatalogApp/SplashscreenKatalog/index.jsx";
import FormLaporanBataltitip from "../pages/Laporan/LaporanPenjualan/LaporanBatalTitip/FormLaporanBatalTitip.jsx";
import LaporanBatalPesanan from "../pages/Laporan/LaporanPenjualan/LaporanBatalPesanan/index.jsx";
import LaporanPesananBarang from "../pages/Laporan/LaporanPesananBarang/index.jsx";
import FormValidasiBeli from "../pages/Kasir/ValidasiBeli/FormValidasiBeli.jsx";

const DataBarang = lazy(() => import("../pages/Barang/DataBarang/DataBarang"));
const LaproranHistoryBarangSupplier = lazy(() =>
  import(
    "../pages/Laporan/LaporanBarang/LaporanHistoryBarangSupplier/index.jsx"
  )
);
const ParameterDeskripsiService = lazy(() =>
  import("../pages/DataMaster/ParameterDeskripsiService")
);
const KirimBarangExternal = lazy(() =>
  import("../pages/Barang/KirimBarangExternal")
);
const UpdateBarangExternal = lazy(() =>
  import("../pages/Barang/UpdateBarangExternal")
);
const ParameterHutangPokok = lazy(() =>
  import("../pages/DataMaster/ParameterHutangPokok")
);

const ExportCvs = lazy(() => import("../pages/utility/ExportCvs"));
const ValidasiPesanan = lazy(() => import("../pages/Kasir/ValidasiPesanan"));
const ValidasiService = lazy(() => import("../pages/Kasir/ValidasiService"));
const StockSuciManual = lazy(() => import("../pages/Sepuhan/StockCuciManual"));
const LaporanStockSepuhanManual = lazy(() =>
  import("../pages/Laporan/LaporanSepuhan/LaporanStockSepuhanManual")
);
const LaporanPembelianManual = lazy(() =>
  import("../pages/Laporan/LaporanPembelian/LaporanPembelianManual")
);
const LihatPembelianManual = lazy(() =>
  import("../pages/TransaksiJualBeliManual/LihatPembelianManual")
);
const BatalPembelianManual = lazy(() =>
  import("../pages/TransaksiJualBeliManual/BatalPembelianManual")
);
const LaporanBatalPembelianManual = lazy(() =>
  import("../pages/Laporan/LaporanPembelian/LaporanBatalPembelianManual")
);
const LaporanKartuStockHadiah = lazy(() =>
  import("../pages/Member/LaporanKartuStockHadiah")
);
const LaporanHutangDetail = lazy(() =>
  import("../pages/Laporan/LaporanHutang/LaporanHutangDetail")
);
const MarketPlaceSetting = lazy(() => import("../pages/MarketPlaceSetting"));
const FormLaporanRekapSales = lazy(() =>
  import(
    "../pages/Laporan/LaporanBarang/LaporanRekapSales/FormLaporanRekapSales"
  )
);
const IndexLaporanPembelian = lazy(() =>
  import(
    "../pages/Laporan/LaporanPembelian/LaporanPembelianBarang/indexLaporanPembelian"
  )
);
const IndexLaporanPembelianRekapBerkah = lazy(() =>
  import(
    "../pages/Laporan/LaporanPembelian/LaporanPembelianBarang/IndexLaporanPembelianRekapBerkah"
  )
);
const TambahBarangBanyak = lazy(() =>
  import("../pages/Barang/TambahBarangBanyak")
);
const TutupToko = lazy(() => import("../pages/utility/TutupToko"));
const FormLaporanPenjualanBerlian = lazy(() =>
  import(
    "../pages/Laporan/LaporanPenjualan/LaporanPenjualanBerlian/FormLaporanPenjualanBerlian"
  )
);
const ExportDataMember = lazy(() => import("../pages/Member/ExportDataMember"));
const UpdatePfrofit = lazy(() => import("../pages/DataMaster/UpdateProfit"));
const RedirectForm = lazy(() => import("../pages/utility/RedirectForm"));
const AksesTidakDiizinkan = lazy(() =>
  import("../components/content/AksesTidakDiizinkan")
);
const FormLaporanPembelianPerKondisi = lazy(() =>
  import(
    "../pages/Laporan/LaporanPembelian/LaporanPembelianPerKondisi/FormLaporanPembelianPerKondisi"
  )
);
const FormLaporanAkhirHari = lazy(() =>
  import("../pages/Laporan/LaporanAkhirHari/FormLaporanAkhirHari")
);
const FormHarianEmas = lazy(() =>
  import("../pages/Laporan/LaporanBarang/FormLaporanHarianEmas/FormHarianEmas")
);
const FormLaporanKeuanganAkhirHari = lazy(() =>
  import(
    "../pages/Laporan/LaporanKeuanganAkhirHari/FormLaporanKeuanganAkhirHari"
  )
);

const FormLelangHutang = lazy(() =>
  import("../pages/Hutang/LelangHutang/FormLelangHutang")
);
const FormLaporanLelangHutang = lazy(() =>
  import(
    "../pages/Laporan/LaporanHutang/LaporanLelangHutang/FormLaporanLelangHutang"
  )
);
const LaporanStockBeli = lazy(() =>
  import("../pages/Laporan/LaporanPembelian/LaporanStockBeli")
);
const FormValidasiJualBeliManual = lazy(() =>
  import("../pages/Kasir/ValidasiJualBeliManual/FormValidasiJualBeliManual")
);
const LaporanJualBeliSalesManual = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanJualBeliSalesManual")
);
const MaterParameterGetPoint = lazy(() =>
  import("../pages/Member/ParameterPointGetMember/MaterParameterGetPoint")
);
const FormLapGlobalPenjualanGajah = lazy(() =>
  import(
    "../pages/Laporan/LaporanPenjualan/LaporanGlobalPenjualanGajah/FormLapGlobalPenjualanGajah"
  )
);
const LaporanBatalTukar = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanBatalTukar")
);

const PengalihanSaldoUang = lazy(() =>
  import("../pages/DataMaster/PengalihanSaldoUang")
);
const LaporanPenjualanTaxPurpose = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanPenjualanTaxPurpose")
);
const LaporanBarangManualSummary = lazy(() =>
  import("../pages/Laporan/LaporanBarang/LaporanBarangManualSummary")
);
const PageError = lazy(() => import("../pages/404"));
const ValidasiNonCash = lazy(() => import("../pages/Kasir/ValidasiNonCash"));
const PageNotFound = lazy(() => import("../pages/PageNotFound"));
const GesekTunai = lazy(() => import("../pages/UangKas/GesekTunai"));
const LaporanGesekTunai = lazy(() =>
  import("../pages/Laporan/LaporanKeuangan/LaporanGesekTunai")
);
const TransakiPreOrder = lazy(() =>
  import("../pages/PreOrder/TransaksiPreOrder")
);
const LihatPreOrder = lazy(() => import("../pages/PreOrder/LihatPreOrder"));
const ImportPreOrder = lazy(() => import("../pages/PreOrder/ImportPreOrder"));
const LaporanTransaksiGlobalPo = lazy(() =>
  import("../pages/Laporan/LaporanPo/LaporanTransaksiGlobalPo")
);
const LaporanPenjualanPo = lazy(() =>
  import("../pages/Laporan/LaporanPo/LaporanPenjualanPo")
);
const LaporanOutstandPo = lazy(() =>
  import("../pages/Laporan/LaporanPo/LaporanOutstandPo")
);
const LaporanPembelianTransfer = lazy(() =>
  import("../pages/Laporan/LaporanPembelian/LaporanPembelianTransfer")
);
const ClosingPo = lazy(() => import("../pages/PreOrder/ClosingPo"));
const LaporanClosingBarangPo = lazy(() =>
  import("../pages/Laporan/LaporanPo/LaporanClosingBarangPo")
);
const WaSetting = lazy(() => import("../pages/WaSetting"));

const SettingMember = lazy(() => import("../pages/utility/SettingMember"));
const LaporanAktifitasTimbangBaki = lazy(() =>
  import("../pages/Laporan/LaporanAktifitasTimbangBaki")
);
const DataBarangManual = lazy(() =>
  import("../pages/BarangManual/DataBarangManual")
);
const HancurBarangManual = lazy(() =>
  import("../pages/BarangManual/HancurBarangManual")
);
const PindahBarangManual = lazy(() =>
  import("../pages/BarangManual/PindahBarangManual")
);
const MasterKelompokHarga = lazy(() =>
  import("../pages/DataMaster/MasterKelompokHarga")
);
const LaporanHancurBarangManual = lazy(() =>
  import("../pages/Laporan/LaporanBarang/LaporanHancurBarangManual")
);
const LaporanPindahBarangManual = lazy(() =>
  import("../pages/Laporan/LaporanBarang/LaporanPindahBarangManual")
);
const LaporanTambahBarangManual = lazy(() =>
  import("../pages/Laporan/LaporanBarang/LaporanTambahBarangManual")
);
const LaporanGlobalStockPinjam = lazy(() =>
  import("../pages/Laporan/LaporanGlobalStockPinjam")
);
const LaporanBatalPenjualanManual = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanBatalPenjualanManual")
);
const LaporanPenjualanManual = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanPenjualanManual")
);
const BatalPenjualanManual = lazy(() =>
  import("../pages/TransaksiJualBeliManual/BatalPenjualanManual")
);
const LihatPenjualanManual = lazy(() =>
  import("../pages/TransaksiJualBeliManual/LihatPenjualanManual")
);
const TransaksiPenjualanManual = lazy(() =>
  import("../pages/TransaksiJualBeliManual/TransaksiJualBeliManualView")
);
const SettingNota = lazy(() => import("../pages/utility/SettingNota"));
const LaporanHancurPembelian = lazy(() =>
  import("../pages/Laporan/LaporanPembelian/LaporanHancurPembelian")
);
const LaporanWestingSepuhan = lazy(() =>
  import("../pages/Laporan/LaporanSepuhan/LaporanWestingSepuhan")
);
const LaporanStockOpnameRfid = lazy(() =>
  import("../pages/Laporan/LaporanStockOpnameRfid")
);
const HancurBeli = lazy(() => import("../pages/Pembelian/HancurBeli"));
const AmbilBarangContoh = lazy(() =>
  import("../pages/Pesanan/AmbilBarangContoh")
);
const StockOpnameRfid = lazy(() =>
  import("../pages/StockOpname/StockOpnameRfid")
);
const JenisBatu = lazy(() =>
  import("../pages/DataMaster/Berlian/JenisBatu/index.jsx")
);
const HakAksesNews = lazy(() => import("../pages/HakAksesNews.jsx"));
const MasterSupplierBerlian = lazy(() =>
  import("../pages/DataMaster/DataSupplierBerlian/MasterSupplierBerlian")
);
const WastingSepuhan = lazy(() =>
  import("../pages/Sepuhan/WastingSepuhan/index.jsx")
);
const KirimBarang = lazy(() => import("../pages/Barang/KirimBarang/index.jsx"));
const MasterToko = lazy(() => import("./../pages/DataMaster/MasterToko/index"));
const ParameterOngkos = lazy(() =>
  import("./../pages/DataMaster/ParameterOngkos/index")
);
const LaporanSortirPembelian = lazy(() =>
  import("../pages/Laporan/LaporanSepuhan/LaporanSortirPembelian/index.jsx")
);
const LaporanPindahSortir = lazy(() =>
  import("../pages/Laporan/LaporanSepuhan/LaporanPindahSortir/index.jsx")
);
const LaporanSaldoBarangAktif = lazy(() =>
  import("../pages/Laporan/LaporanSepuhan/LaporanSaldoBarangAktif/index.jsx")
);
const AksesNotFound = lazy(() => import("../pages/AksesNotFound.jsx"));
const LaporanKirimBarang = lazy(() =>
  import("./../pages/Laporan/LaporanBarang/LaporanKirimBarang/index")
);
const SubMenuBarang = lazy(() => import("../pages/pos_home/sub_barang.jsx"));
const SubMenuPembelian = lazy(() =>
  import("../pages/pos_home/sub_pembelian.jsx")
);
const SubMenuPenjualan = lazy(() =>
  import("../pages/pos_home/sub_penjualan.jsx")
);
const SubMenuPesanan = lazy(() => import("../pages/pos_home/sub_pesanan.jsx"));
const SubMenuService = lazy(() => import("../pages/pos_home/sub_service.jsx"));
const SubMenuHutang = lazy(() => import("../pages/pos_home/sub_hutang.jsx"));
const SubMenuValidasi = lazy(() => import("../pages/pos_home/sub_validasi"));
const SubMenuLaporan = lazy(() => import("../pages/pos_home/sub_laporan"));
const SubMenuUtility = lazy(() => import("../pages/pos_home/sub_utility"));
const SubMenuMember = lazy(() => import("../pages/pos_home/sub_member"));
const DashboardV2 = lazy(() => import("../pages/Dashboard/dashboard_pusat"));
const TerimaUangPusat = lazy(() => import("../pages/UangKas/TerimaUangPusat"));
const LaporanKirimUangPusat = lazy(() =>
  import("../pages/Laporan/LaporanKeuangan/KirimUangPusat")
);
const LaporanTerimaUangPusat = lazy(() =>
  import("../pages/Laporan/LaporanKeuangan/TerimaUangPusat")
);
const UangBank = lazy(() => import("../pages/UangKas/UangBank/index.jsx"));
const PerubahanHargaEmas = lazy(() =>
  import("../pages/Laporan/PerubahanHargaEmas/index.jsx")
);
const LaporanStockOpname = lazy(() =>
  import("../pages/Laporan/LaporanStockOpname/index.jsx")
);
const LaporanKeuanganGlobalCash = lazy(() =>
  import("../pages/Laporan/LaporanKeuangan/LaporanKeuanganGlobalCash")
);
const LaporanGlobalTransaksi = lazy(() =>
  import(
    "../pages/Laporan/LaporanKeuangan/GlobalTransaksi/LaporanGlobalTransaksi"
  )
);
const TerimaSepuhan = lazy(() =>
  import("../pages/Sepuhan/TerimaSepuhan/index.jsx")
);
const ValidasiSusut = lazy(() =>
  import("../pages/Sepuhan/ValidasiSusut/index.jsx")
);
const LaporanValidasiSusut = lazy(() =>
  import("../pages/Laporan/LaporanSepuhan/LaporanValidasiSusut/index.jsx")
);
const StockOpnaemeGlobal = lazy(() =>
  import("../pages/StockOpname/StockOpnaemeGlobal/index.jsx")
);
const LaporanStockOpnameGlobal = lazy(() =>
  import("../pages/Laporan/LaporanStockOpnameGlobal/index.jsx")
);
const PlafondHutang = lazy(() =>
  import("../pages/DataMaster/PlafondHutang/index.jsx")
);
const KirimBarangCuci = lazy(() =>
  import("../pages/Sepuhan/KirimBarangCuci/index.jsx")
);
const TerimaBarangCuci = lazy(() =>
  import("../pages/Sepuhan/TerimaBarangCuci/index.jsx")
);
const LaporanKirimBarangKepusat = lazy(() =>
  import("../pages/Laporan/LaporanSepuhan/LaporanKirimBarangKepusat/index.jsx")
);
const ParameterKeteranganNota = lazy(() =>
  import("../pages/DataMaster/ParameterKeteranganNota/index.jsx")
);
const IndexLaporanEod = lazy(() =>
  import("../pages/Laporan/LaporanEod/index.jsx")
);
const LaporanManagerialAnalisys = lazy(() =>
  import("../pages/Laporan/LaporanManagerialAnalisys/index.jsx")
);
const MasterJenisGroup = lazy(() =>
  import("../pages/DataMaster/ParameterJenisGroup/MasterJenisGroup.jsx")
);
const PotonganPenjualan = lazy(() =>
  import("../pages/DataMaster/PotonganPenjualan/index.jsx")
);
const ParameterKupon = lazy(() =>
  import("../pages/DataMaster/ParameterKupon/index.jsx")
);
const CekKuponPage = lazy(() => import("../pages/Kupon/CekKupon/index.jsx"));
const LaporanTopMember = lazy(() =>
  import("../pages/Member/LaporanTopMember/index.jsx")
);
const ParameterBonusSales = lazy(() =>
  import("../pages/DataMaster/ParameterBonusSales/index.jsx")
);
const LaporanBayarPoint = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanBayarPoint/index.jsx")
);
const LaporanTransaksiHarian = lazy(() =>
  import("../pages/Laporan/LaporanTransaksiHarian/index.jsx")
);
const ParameterResetPoinMember = lazy(() =>
  import("../pages/DataMaster/ParameterResetPoinMember/index.jsx")
);
const LaporanBarangAcc = lazy(() =>
  import("../pages/Laporan/LaporanBarang/LaporanBarangAcc/index.jsx")
);
const LaporanPenjualanGlobalJenis = lazy(() =>
  import(
    "../pages/Laporan/LaporanPenjualan/LaporanPenjualanGlobalJenis/index.jsx"
  )
);
const LaporanHarianMember = lazy(() =>
  import("../pages/Member/LaporanHarianMember/index.jsx")
);
const LaporanPinjamanRekap = lazy(() =>
  import("../pages/Laporan/LaporanHutang/LaporanPinjamanRekap/index.jsx")
);
const LaporanPembelianGlobalJenis = lazy(() =>
  import(
    "../pages/Laporan/LaporanPembelian/LaporanPembelianGlobalJenis/index.jsx"
  )
);
const LaporanGlobalSales = lazy(() =>
  import("../pages/Laporan/LaporanGlobalSales/index.jsx")
);
const LaporanGlobalStockToko = lazy(() =>
  import("../pages/Laporan/LaporanGlobalStockToko/index.jsx")
);
const LaporanTransaksiCash = lazy(() =>
  import("../pages/Laporan/LaporanTransaksiCash/index.jsx")
);
const DataSupllier = lazy(() =>
  import("../pages/DataMaster/DataSupllier/index.jsx")
);
const LaporanKirimBarangOnline = lazy(() =>
  import("../pages/Laporan/LaporanBarang/LaporanKirimBarangOnline/index.jsx")
);
const LaporanBayarCashback = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanBayarCashback/index.jsx")
);
const ParameterBungaHutang = lazy(() =>
  import("../pages/DataMaster/ParameterBungaHutang/index.jsx")
);
const ParameterKadarHutang = lazy(() =>
  import("../pages/DataMaster/ParameterKadarHutang/index.jsx")
);
const BatalHutangLunas = lazy(() =>
  import("../pages/Hutang/BatalHutangLunas/index.jsx")
);

const PenerimaanBaarang = lazy(() =>
  import("../pages/Barang/PenerimaanBaarang/index.jsx")
);
const BatalPenerimaanBarang = lazy(() =>
  import("../pages/Barang/BatalPenerimaanBarang/index.jsx")
);
const MasterBank = lazy(() =>
  import("../pages/DataMaster/MasterBank/index.jsx")
);
const MasterFakturBermasalah = lazy(() =>
  import("../pages/DataMaster/MasterFakturBermasalah/index.jsx")
);
const MasterKadarEod = lazy(() =>
  import("../pages/DataMaster/MasterKadarEod/index.jsx")
);
const MasterKota = lazy(() => import("../pages/DataMaster/DataKota/index.jsx"));
const MasterKecamatan = lazy(() =>
  import("../pages/DataMaster/DataKecamatan/index.jsx")
);
const MasterRekening = lazy(() =>
  import("../pages/DataMaster/MasterRekening/index.jsx")
);
const LaporanTerimaBarangSupplier = lazy(() =>
  import("../pages/Laporan/LaporanBarang/LaporanTerimaBarangSupplier/index.jsx")
);
const LaporanPenjualanMargin = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanPenjualanMargin/index.jsx")
);
const StockOpnamePenerimaan = lazy(() =>
  import("../pages/StockOpname/StockOpnamePenerimaan/index.jsx")
);
const LihatUangKas = lazy(() =>
  import("../pages/UangKas/LihatUangKas/index.jsx")
);
const KirimUangPusat = lazy(() =>
  import("../pages/UangKas/KirimUangPusat/index.jsx")
);
const BukiBerlian = lazy(() =>
  import("../pages/Laporan/LaporanBerlian/BukuBerlian")
);

const BatuBerlian = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuBerlian/index.jsx")
);
const BatuCutgrade = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuCutgrade/index.jsx")
);
const BatuPolish = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuPolish/index.jsx")
);
const BatuSymetry = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuSymetry/index.jsx")
);
const BatuFluorencence = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuFluorencence/index.jsx")
);
const BatuClarity = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuClarity/index.jsx")
);
const BatuColor = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuColor/index.jsx")
);
const BatuShape = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuShape/index.jsx")
);
const FormhargaEmas = lazy(() =>
  import("../pages/DataMaster/ParameterHargaEmas/FormhargaEmas.jsx")
);
const FormParamterPoint = lazy(() =>
  import("../pages/DataMaster/ParameterPoint/FormParamterPoint.jsx")
);
const FormLihatHutang = lazy(() =>
  import("../pages/Hutang/LihatHutang/FormLihatHutang.jsx")
);
const FormTransaksiHutang = lazy(() =>
  import("../pages/Hutang/TransaksiHutang/FormTransaksiHutang.jsx")
);
const FormValidasiHutang = lazy(() =>
  import("../pages/Hutang/ValidasiHutang/FormValidasiHutang.jsx")
);
const FormValidasiJualBeli = lazy(() =>
  import("../pages/Kasir/ValidasiJualBeli/FormValidasiJualBeli.jsx")
);
const FormValidasiTitipan = lazy(() =>
  import("../pages/Kasir/ValidasiTitipan/FormValidasiTitipan.jsx")
);
const FormLaporanBarangSumary = lazy(() =>
  import(
    "../pages/Laporan/LaporanBarang/LaporanBarangSumary/FormLaporanBarangSumary.jsx"
  )
);
const FormLaporanBarangSumaryPeriode = lazy(() =>
  import(
    "../pages/Laporan/LaporanBarang/LaporanBarangSumaryPeriode/FormLaporanBarangSumaryPeriode.jsx"
  )
);
// const FormLaporanEod = lazy(() =>
//   import("../pages/Laporan/LaporanEod/LaporanEodPaket/FormLaporanEod.jsx")
// );
const FormLaporanBatalHutang = lazy(() =>
  import(
    "../pages/Laporan/LaporanHutang/LaporanBatalHutang/FormLaporanBatalHutang.jsx"
  )
);
const FormLaporanHutangLunas = lazy(() =>
  import(
    "../pages/Laporan/LaporanHutang/LaporanHutangLunas/FormLaporanHutangLunas.jsx"
  )
);
const FormLaporanHutang = lazy(() =>
  import(
    "../pages/Laporan/LaporanHutang/LaporanTransaksiHutang/FormLaporanHutang.jsx"
  )
);
const FormLaporanMember = lazy(() =>
  import("../pages/Laporan/LaporanMember/FormLaporanMember.jsx")
);
const FormLaporanBatalBeli = lazy(() =>
  import(
    "../pages/Laporan/LaporanPembelian/LaporanBatalPembelian/FormLaporanBatalBeli.jsx"
  )
);
const FormLaporanBatalService = lazy(() =>
  import(
    "../pages/Laporan/LaporanService/LaporanBatalService/FormLaporanBatalService.jsx"
  )
);
const FormLaporanServiceAmbil = lazy(() =>
  import(
    "../pages/Laporan/LaporanService/LaporanServiceAmbil/FormLaporanServiceAmbil.jsx"
  )
);
const FormLaporanService = lazy(() =>
  import(
    "../pages/Laporan/LaporanService/LaporanTransaksiService/FormLaporanService.jsx"
  )
);
const FormHakAkses = lazy(() =>
  import("../pages/ManageUsers/HakAkses/index.jsx")
);
const DataMember = lazy(() => import("../pages/Member/DataMember.jsx"));
const LihatTransaksiMember = lazy(() =>
  import("../pages/Member/LihatTransaksiMember/LihatTransaksiMember.jsx")
);
const FormTransaksiPoin = lazy(() =>
  import("../pages/Member/TransaksiPoin/FormTransaksiPoin.jsx")
);
const FormTukarPoin = lazy(() =>
  import("../pages/Member/TukarPoin/FormTukarPoin.jsx")
);
const FormLihatPembelian = lazy(() =>
  import("../pages/Pembelian/LihatPembelian/FormLihatPembelian.jsx")
);
const BatalTitipan = lazy(() =>
  import("../pages/Penjualan/BatalTitipan/index.jsx")
);
const FormLihatPenjualan = lazy(() =>
  import("../pages/Penjualan/LihatPenjualan/FormLihatPenjualan.jsx")
);
const FormValidasiPenjualan = lazy(() =>
  import("../pages/Penjualan/ValidasiPenjualan/FormValidasiPenjualan.jsx")
);
const Pesanan = lazy(() => import("../pages/Pesanan/index.jsx"));
const LihatPesanan = lazy(() =>
  import("../pages/Pesanan/LihatPesanan/index.jsx")
);
const LihatServices = lazy(() =>
  import("../pages/Service/LihatServices/LihatServices.jsx")
);
const FormTransaksiServces = lazy(() =>
  import("../pages/Service/TransaksiServices/FormTransaksiServces.jsx")
);
const FormStockOpname = lazy(() =>
  import("../pages/StockOpname/FormStockOpname.jsx")
);
const SettingNotaSlip = lazy(() =>
  import("../pages/utility/SettingNotaSlip/SettingNotaSlip.jsx")
);
const SettingSystem = lazy(() =>
  import("../pages/utility/SettingSystem/SettingSystem.jsx")
);
const FormLaporanTambahBarang = lazy(() =>
  import(
    "../pages/Laporan/LaporanBarang/FormLaporanTambahBarang/FormLaporanTambahBarang.jsx"
  )
);
const LaporanNonCash = lazy(() =>
  import("../pages/Laporan/LaporanKeuangan/LaporanNonCash/index.jsx")
);
const LaporanOtorisasi = lazy(() =>
  import("../pages/Laporan/LaporanOtorisasi/index.jsx")
);
const TimbangBarang = lazy(() =>
  import("../pages/Barang/TimbangBarang/TimbangBarang.jsx")
);
const FormLaporanTimbangBarang = lazy(() =>
  import(
    "../pages/Laporan/LaporanBarang/FormLaporanTimbangBarang/FormLaporanTimbangBarang.jsx"
  )
);
const CetakBarcode = lazy(() =>
  import("../pages/utility/CetakBarcode/CetakBarcode.jsx")
);
const FormLaporanPenjualanTukar = lazy(() =>
  import(
    "../pages/Laporan/LaporanPenjualan/LaporanPenjualanTukar/FormLaporanPenjualanTukar.jsx"
  )
);
const FormLaporanPembelianBarangHistory = lazy(() =>
  import(
    "../pages/Laporan/LaporanPembelian/LaporanPembelianBarangHistory/FormLaporanPembelianBarangHistory.jsx"
  )
);
// const Cucian = lazy(() => import("../pages/Cucian/index.jsx"));
const PindahSortir = lazy(() =>
  import("../pages/Sepuhan/PindahSortir/index.jsx")
);
const SettingToko = lazy(() =>
  import("../pages/utility/SettingToko/index.jsx")
);

const DataMareketPlace = lazy(() =>
  import("../pages/DataMaster/DataMarketPlace")
);
const LaporanPenjualanMarketPlace = lazy(() =>
  import(
    "../pages/Laporan/LaporanPenjualan/LaporanPenjualanMarketPlace/index.jsx"
  )
);
const LaporanStockJual = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanStockJual/index.jsx")
);
const LaporanServiceMasuk = lazy(() =>
  import("../pages/Laporan/LaporanService/LaporanServiceMasuk/index.jsx")
);
const LaporanServiceSelesai = lazy(() =>
  import("../pages/Laporan/LaporanService/LaporanServiceSelesai/index")
);
const LaporanStockHutang = lazy(() =>
  import("../pages/Laporan/LaporanHutang/LaporanStockHutang/index.jsx")
);
const LaporanTitipUang = lazy(() =>
  import("../pages/Laporan/LaporanHutang/LaporanTitipUang/index.jsx")
);
const LaporanPesanan = lazy(() =>
  import("../pages/Laporan/LaporanPesanan/index.jsx")
);
const TerimaBarang = lazy(() =>
  import("../pages/Barang/TerimaBarang/TerimaBarang.jsx")
);
const FormLaporanTerimaBarang = lazy(() =>
  import(
    "../pages/Laporan/LaporanBarang/LaporanTerimaBarang/FormLaporanTerimaBarang.jsx"
  )
);
const BlankPages = lazy(() => import("../pages/BlankPages.jsx"));
const TambahStokAkesoris = lazy(() =>
  import("../pages/DataMaster/TambahStokAkesoris")
);

const LaporanKeuanganKas = lazy(() =>
  import("../pages/Laporan/LaporanKeuangan/Kas/LaporanKeuanganKas.jsx")
);
const ImportDataPusat = lazy(() =>
  import("../pages/ManageData/ImportDataPusat/ImportDataPusat.jsx")
);
const KirimDataBarang = lazy(() =>
  import("../pages/ManageData/KirimBarangKeCabang/KirimDataBarang.jsx")
);
const FormPindahBarang = lazy(() =>
  import("../pages/Barang/PindahBarang/FormPindahBarang.jsx")
);
const FormLaporanPindahBarang = lazy(() =>
  import(
    "../pages/Laporan/LaporanBarang/LaporanPindahBarang/FormLaporanPindahBarang.jsx"
  )
);
const FormPindahBarangSatuBaki = lazy(() =>
  import("../pages/Barang/PindahBarangSatuBaki/FormPindahBarangSatuBaki.jsx")
);

//Penjualan
const FormTransaksiPenjualan = lazy(() =>
  import("../pages/Penjualan/TransaksiPenjualan/FormTransaksiPenjualan.jsx")
);
const FormLaporanPenjualanBarang = lazy(() =>
  import(
    "../pages/Laporan/LaporanPenjualan/LaporanPenjualanBarang/FormLaporanPenjualanBarang.jsx"
  )
);
const FormLaporanPenjualanCicilan = lazy(() =>
  import(
    "../pages/Laporan/LaporanPenjualan/LapoanPenjualanCicilan/FormLaporanPenjualanCicilan"
  )
);
const LaporanPenjualanSales = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/PenjualanSales")
);
const FormLaporanPenjualanBayar = lazy(() =>
  import(
    "../pages/Laporan/LaporanPenjualan/PenjualanBayar/FormLaporanPenjualanBayar.jsx"
  )
);
const FormBatalPenjualan = lazy(() =>
  import("../pages/Penjualan/BatalPenjualan/FormBatalPenjualan.jsx")
);
const FormLaporanBatalPenjualan = lazy(() =>
  import(
    "../pages/Laporan/LaporanPenjualan/LaporanBatalPenjualan/FormLaporanBatalPenjualan.jsx"
  )
);

//Pembelian
const FormLaporanPembelianSales = lazy(() =>
  import(
    "../pages/Laporan/LaporanPembelian/PembelianSales/FormLaporanPembelianSales.jsx"
  )
);
const FormTransaksiPembelian = lazy(() =>
  import("../pages/Pembelian/TransaksiPembelian/FormTransaksiPembelian.jsx")
);
// const IndexLaporanPembelian = lazy(() =>
//   import(
//     "../pages/Laporan/LaporanPembelian/LaporanPembelianBarang/IndexLaporanPembelian.jsx"
//   )
// );
// const FormLaporanPembelianBarang = lazy(() =>
//   import(
//     "../pages/Laporan/LaporanPembelian/LaporanPembelianBarang/FormLaporanPembelianBarang.jsx"
//   )
// );
const FormBatalPembelian = lazy(() =>
  import("../pages/Pembelian/BatalPembelian/FormBatalPembelian.jsx")
);
// End Pembelian

//Barang
const TambahBarang = lazy(() =>
  import("../pages/Barang/TambahBarang/TambahBarang.jsx")
);
const FormHancurBarang = lazy(() =>
  import("../pages/Barang/HancurDataBarang/FormHancurBarang.jsx")
);
const FormHancurBarangPSU = lazy(() =>
  import("../pages/Barang/HancurDataBarangPSU/FormHancurBarangPSU.jsx")
);

const FormDetailBarang = lazy(() =>
  import(
    "../pages/Laporan/LaporanBarang/FormLaporanDetailBarang/FormDetailBarang.jsx"
  )
);
const FormLaporanHancurBarang = lazy(() =>
  import(
    "../pages/Laporan/LaporanBarang/LaporanHancurBarang/FormLaporanHancurBarang.jsx"
  )
);
// End Barang

//Data Master
const MasterGroup = lazy(() =>
  import("../pages/DataMaster/DataGroup/MasterGroup.jsx")
);
const MasterKadar = lazy(() =>
  import("../pages/DataMaster/DataKadar/MasterKadar.jsx")
);
const DataUsers = lazy(() =>
  import("../pages/ManageUsers/DataUsers/DataUsers.jsx")
);
const MasterBandrol = lazy(() =>
  import("../pages/DataMaster/DataBandrol/MasterBandrol.jsx")
);
const MasterHadiah = lazy(() =>
  import("../pages/DataMaster/DataHadiah/MasterHadiah.jsx")
);
const MasterJenis = lazy(() =>
  import("../pages/DataMaster/DataJenis/MasterJenis.jsx")
);
const MasterKodeTransaksi = lazy(() =>
  import("../pages/DataMaster/DataKodeTransaksi/MasterKodeTransaksi.jsx")
);
const MasterKondisiBarang = lazy(() =>
  import("../pages/DataMaster/DataKondisiBarang/MasterKondisiBarang.jsx")
);
const MasterTukang = lazy(() =>
  import("../pages/DataMaster/DataTukang/MasterTukang.jsx")
);
const MasterKondisiPembelian = lazy(() =>
  import("../pages/DataMaster/KondisiPembelian/MasterKondisiPembelian.jsx")
);
const MasterKondisiPinjaman = lazy(() =>
  import("../pages/DataMaster/KondisiPinjaman/MasterKondisiPinjaman.jsx")
);
const MasterBaki = lazy(() =>
  import("../pages/DataMaster/DataBaki/MasterBaki.jsx")
);
const MasterGudang = lazy(() =>
  import("../pages/DataMaster/DataGudang/MasterGudang.jsx")
);
const MasterSales = lazy(() =>
  import("../pages/DataMaster/DataSales/MasterSales.jsx")
);
const Dashboard = lazy(() => import("./../pages/Dashboard/dashboard.jsx"));
const MasterKategori = lazy(() =>
  import("../pages/DataMaster/DataKategori/MasterKategori.jsx")
);
const MasterParameterRugiNota = lazy(() =>
  import("../pages/DataMaster/ParameterRugiNota/MasterParameterRugiNota.jsx")
);
const SortirPembelian = lazy(() =>
  import("../pages/Sepuhan/SortirPembelian/index")
);
const LaporanGlobalPenjualan = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanGlobalPenjualan")
);
//End Data Master
const PostHomePage = lazy(() => import("../pages/pos_home/index"));
const LaporanPenembusanRekap = lazy(() =>
  import("../pages/Laporan/LaporanHutang/LaporanPenebusanRekap")
);
//Login
const Login = lazy(() => import("./../pages/auth/login.jsx"));

const AppModule = getItem("module") || [];
const appMode = findModuleText("APP_MODE")?.value;

let tokoBerkah =
  ListKodeTokoBerkah.filter(
    (list) => list.kode_toko === getUserdata().kode_toko
  ).length > 0
    ? true
    : ListTokoLaris.filter((list) => list.kode_toko === getUserdata().kode_toko)
        .length > 0
    ? true
    : false;

const routes = [
  {
    path: "/laporan-aktivitas-timbang-baki",
    exact: true,
    title: "Laporan aktivitas Timbang Baki",
    component: () => <LaporanAktifitasTimbangBaki />,
  },
  {
    path: "/laporan-history-barang-supplier",
    exact: true,
    title: "Laporan History Barang Supplier",
    component: () => <LaproranHistoryBarangSupplier />,
  },

  {
    path: "/laporan-rekap-sales",
    exact: true,
    title: "Laporan Rekap Sales",
    component: () => (tokoBerkah ? <FormLaporanRekapSales /> : null),
  },
  {
    path: "/marketplace-setting",
    exact: true,
    title: "Marketplace Setting",
    component: () =>
      findModule("MARKETPLACE_INTEGRATION_MODULE") ? (
        <MarketPlaceSetting />
      ) : (
        <AksesNotFound />
      ),
  },
  // Barang Manual
  {
    path: "/setting-nota",
    exact: true,
    title: "Setting Nota",
    // component: () => <SettingNota />,
    component: () =>
      findModule("SETTING_NOTA_OWNER_ONLY") ? (
        getUserdata().level === "OWN" || getUserdata().level === "SU" ? (
          <SettingNota />
        ) : (
          <AksesNotFound />
        )
      ) : (
        <SettingNota />
      ),
  },
  {
    path: "/kirim-barang-external",
    exact: true,
    title: "Kirim Barang External",
    component: () =>
      AppModule.findIndex((list) => list.key === "BARANG_WEB_EXTERNAL_MODULE") ===
      -1 ? (
        <AksesNotFound />
      ) : (
        <KirimBarangExternal />
      ),
  },

  {
    path: "/update-barang-external",
    exact: true,
    title: "Update Barang External",
    component: () =>
      AppModule.findIndex((list) => list.key === "BARANG_WEB_EXTERNAL_MODULE") ===
      -1 ? (
        <AksesNotFound />
      ) : (
        <UpdateBarangExternal />
      ),
  },
  {
    path: "/data-barang-manual",
    exact: true,
    title: "Data Barang Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <DataBarangManual />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <DataBarangManual />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/pindah-barang-manual",
    exact: true,
    title: "Pindah Barang Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <PindahBarangManual />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <PindahBarangManual />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/hancur-barang-manual",
    exact: true,
    title: "Hancur Barang Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <HancurBarangManual />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <HancurBarangManual />
      ) : (
        <AksesNotFound />
      ),
  },

  {
    path: "/laporan-tambah-barang-manual",
    exact: true,
    title: "Laporan Tambah Barang Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <LaporanTambahBarangManual />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <LaporanTambahBarangManual />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/laporan-pindah-barang-manual",
    exact: true,
    title: "Laporan Pindah Barang Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <LaporanPindahBarangManual />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <LaporanPindahBarangManual />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/laporan-hancur-barang-manual",
    exact: true,
    title: "Laporan Hancur Barang Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <LaporanHancurBarangManual />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <LaporanHancurBarangManual />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/laporan-summary-barang-manual",
    exact: true,
    title: "Laporan Summary Barang Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <LaporanBarangManualSummary />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <LaporanBarangManualSummary />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/transaksi-jual-beli-manual",
    exact: true,
    title: "Transaksi Jual Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <TransaksiPenjualanManual />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <TransaksiPenjualanManual />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/lihat-penjualan-manual",
    exact: true,
    title: "Lihat Penjualan Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <LihatPenjualanManual />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <LihatPenjualanManual />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/lihat-pembelian-manual",
    exact: true,
    title: "Lihat Pembelian Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <LihatPembelianManual />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <LihatPembelianManual />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/batal-pembelian-manual",
    exact: true,
    title: "Batal Pembelian Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <BatalPembelianManual />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <BatalPembelianManual />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/batal-penjualan-manual",
    exact: true,
    title: "Batal Penjualan Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <BatalPenjualanManual />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <BatalPenjualanManual />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/laporan-penjualan-manual",
    exact: true,
    title: "Laporan Penjualan Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <LaporanPenjualanManual />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <LaporanPenjualanManual />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/laporan-pembelian-manual",
    exact: true,
    title: "Laporan Pembelian Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <LaporanPembelianManual />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <LaporanPembelianManual />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/laporan-batal-penjualan-manual",
    exact: true,
    title: "Laporan Batal Penjualan Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <LaporanBatalPenjualanManual />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <LaporanBatalPenjualanManual />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/laporan-batal-pembelian-manual",
    exact: true,
    title: "Laporan Batal Pembelian Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <LaporanBatalPembelianManual />
      ) : appMode === "STOCK_JUAL_MANUAL" ? (
        <LaporanBatalPembelianManual />
      ) : (
        <AksesNotFound />
      ),
  },

  //Cicilan
  {
    path: "/parameter-cicilan",
    exact: true,
    title: "Parameter Cicilan",
    component: () =>
      findModule("CICILAN_MODULE") ? <ParameterCicilan /> : <AksesNotFound />,
  },
  {
    path: "/transaksi-cicilan",
    exact: true,
    title: "Transaksi Cicilan",
    component: () =>
      findModule("CICILAN_MODULE") ? <TransaksiCicilan /> : <AksesNotFound />,
  },
  {
    path: "/lihat-cicilan",
    exact: true,
    title: "Lihat Cicilan",
    component: () =>
      findModule("CICILAN_MODULE") ? <LihatCicilan /> : <AksesNotFound />,
  },
  {
    path: "/lelang-cicilan",
    exact: true,
    title: "Lelang Cicilan",
    component: () =>
      findModule("CICILAN_MODULE") ? <LelangCicilan /> : <AksesNotFound />,
  },
  {
    path: "/batal-cicilan",
    exact: true,
    title: "Batal Cicilan",
    component: () =>
      findModule("CICILAN_MODULE") ? <BatalCicilan /> : <AksesNotFound />,
  },

  // Hidup Module //
  {
    path: "/master-kelompok-harga",
    exact: true,
    title: "Master Kelompok Harga",
    component: () =>
      AppModule.findIndex((list) => list.key === "HIDUP_FEATURE_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <MasterKelompokHarga />
      ),
  },
  {
    path: "/laporan-pinjaman-rekap",
    exact: true,
    title: "Laporan Pinjaman Rekap",
    component: () =>
      AppModule.findIndex((list) => list.key === "HIDUP_FEATURE_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanPinjamanRekap />
      ),
  },
  {
    path: "/laporan-penebusan-rekap",
    exact: true,
    title: "Laporan Penebusan Rekap",
    component: () =>
      AppModule.findIndex((list) => list.key === "HIDUP_FEATURE_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanPenembusanRekap />
      ),
  },
  {
    path: "/laporan-global-penjualan",
    exact: true,
    title: "Laporan Global Penjualan",
    component: () =>
      AppModule.findIndex((list) => list.key === "HIDUP_FEATURE_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanGlobalPenjualan />
      ),
  },
  {
    path: "/laporan-global-pembelian",
    exact: true,
    title: "Laporan Pembelian Global Jenis",
    component: () =>
      AppModule.findIndex((list) => list.key === "HIDUP_FEATURE_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanPembelianGlobalJenis />
      ),
  },
  {
    path: "/laporan-global-sales",
    exact: true,
    title: "Laporan Global Sales",
    component: () =>
      AppModule.findIndex((list) => list.key === "HIDUP_FEATURE_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanGlobalSales />
      ),
  },
  {
    path: "/laporan-global-stock-pinjam",
    exact: true,
    title: "Laporan Global Stock Pinjam",
    component: () =>
      AppModule.findIndex((list) => list.key === "HIDUP_FEATURE_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanGlobalStockPinjam />
      ),
  },
  {
    path: "/laporan-pembelian-perfaktur",
    exact: true,
    title: "Laporan Pembelian Perfaktur",
    component: () =>
      AppModule.findIndex((list) => list.key === "HIDUP_FEATURE_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanPembelianPerfaktur />
      ),
  },
  // {
  //   path: "/kartu-stock-hadiah",
  //   exact: true,
  //   title: "Kartu Stock Hadiah",
  //   component: () =>
  //     AppModule.findIndex((list) => list.key === "MEMBER_MODULE") === -1 ? (
  //       <AksesNotFound />
  //     ) : (
  //       <LaporanMutasiHadiah />
  //     )
  // },
  {
    path: "/laporan-kartu-stock-hadiah",
    exact: true,
    title: "Laporan Kartu Stock Hadiah",
    component: () =>
      findModule("MEMBER_MODULE") ? (
        <LaporanKartuStockHadiah />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/export-data-member",
    exact: true,
    title: "Export Data Member",
    component: () =>
      findModule("MEMBER_MODULE") ? <ExportDataMember /> : <AksesNotFound />,
  },
  {
    path: "/parameter-get-point-member",
    exact: true,
    title: "Parameter Get Point Member",
    component: () =>
      findModule("MEMBER_MODULE") ? (
        <MaterParameterGetPoint />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/laporan-penebusan-detail",
    exact: true,
    title: "Laporan Penebusan",
    component: () =>
      AppModule.findIndex((list) => list.key === "HIDUP_FEATURE_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanPenebusanDetail />
      ),
  },
  {
    path: "/laporan-global-stock-toko",
    exact: true,
    title: "Laporan Global Stock Toko",
    component: () =>
      AppModule.findIndex((list) => list.key === "HIDUP_FEATURE_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanGlobalStockToko />
      ),
  },
  {
    path: "/laporan-transaksi-cash",
    exact: true,
    title: "Laporan Transaksi Cash",
    component: () =>
      AppModule.findIndex((list) => list.key === "HIDUP_FEATURE_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanTransaksiCash />
      ),
  },
  // Hidup Module //
  //Login
  {
    path: "/",
    exact: true,
    title: "Login",
    component: () => <Login />,
  },

  {
    path: "/parameter-ongkos",
    exact: true,
    title: "Parameter Ongkos",
    component: () => <ParameterOngkos />,
  },
  {
    path: "/laporan-penjualan-global-jenis",
    exact: true,
    title: "Laporan Global Penjualan Jenis",
    component: () => <LaporanPenjualanGlobalJenis />,
  },

  {
    path: "/laporan-barang-acc",
    exact: true,
    title: "Laporan Barang Acc",
    component: () => <LaporanBarangAcc />,
  },
  {
    path: "/laporan-keuangan-cash-global",
    exact: true,
    title: "Laporan Cash Global",
    component: () => <LaporanKeuanganGlobalCash />,
  },

  {
    path: "/parameter-hutang-pokok",
    exact: true,
    title: "Parameter Hutang Pokok",
    // PARAMETER_JUMLAH_HUTANG_POKOK_VALUE
    component: () =>
      AppModule.findIndex(
        (list) => list.key === "PARAMETER_JUMLAH_HUTANG_POKOK_MODULE"
      ) === -1 ? (
        <AksesNotFound />
      ) : findModuleText("PARAMETER_JUMLAH_HUTANG_POKOK_VALUE")?.value ===
        "HARGA_EMAS" ? (
        <AksesNotFound />
      ) : (
        <ParameterHutangPokok />
      ),
  },
  {
    path: "/laporan-harian-member",
    exact: true,
    title: "Laporan Harian Member",
    component: () =>
      AppModule.findIndex((list) => list.key === "MEMBER_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanHarianMember />
      ),
  },
  // {
  //   path: "/laporan-hadiah",
  //   exact: true,
  //   title: "Laporan Hadiah",
  //   component: () =>
  //     AppModule.findIndex((list) => list.key === "MEMBER_MODULE") === -1 ? (
  //       <AksesNotFound />
  //     ) : (
  //       <LaporanHadiah />
  //     ),
  // },
  {
    path: "/parameter-reset-poin-member",
    exact: true,
    title: "Parameter Reset Poin Member",
    component: () =>
      AppModule.findIndex((list) => list.key === "MEMBER_RESET_POIN_MODULE") ===
      -1 ? (
        <AksesNotFound />
      ) : (
        <ParameterResetPoinMember />
      ),
  },
  {
    path: "/terima-sepuhan",
    exact: true,
    title: "Terima Sepuhan",
    component: () =>
      findModule("SEPUHAN_MODULE") &&
      findModule("SEPUHAN_TERPUSAT_MODULE") === false ? (
        <TerimaSepuhan />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/laporan-wasting-sepuhan",
    exact: true,
    title: "Laporan Wasting Sepuhan",
    component: () =>
      AppModule.findIndex((list) => list.key === "SEPUHAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanWestingSepuhan />
      ),
  },

  {
    path: "/laporan-transaksi-harian",
    exact: true,
    title: "Laporan Transaksi Harian",
    component: () =>
      AppModule.findIndex(
        (list) => list.key === "DAILY_SALDO_TRANSACTION_REPORT_MODULE"
      ) === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanTransaksiHarian />
      ),
  },
  {
    path: "/laporan-validasi-susut",
    exact: true,
    title: "Laporan Validasi Susut",
    component: () => <LaporanValidasiSusut />,
  },
  {
    path: "/stock-opname-global",
    exact: true,
    title: "Stock Opname Global",
    component: () =>
      AppModule.findIndex((list) => list.key === "OPNAME_GLOBAL_DAILY_MODULE") ===
      -1 ? (
        <AksesNotFound />
      ) : (
        <StockOpnaemeGlobal />
      ),
  },

  {
    path: "/parameter-bunga-hutang",
    exact: true,
    title: "Parameter Bunga Hutang",
    component: () =>
      AppModule.findIndex(
        (list) => list.key === "PARAMETER_BUNGA_HUTANG_MODULE"
      ) === -1 ? (
        <AksesNotFound />
      ) : (
        <ParameterBungaHutang />
      ),
  },
  {
    path: "/plafond-kadar-hutang",
    exact: true,
    title: "Plafond Kadar Hutang",
    component: () =>
      AppModule.findIndex((list) => list.key === "PLAFOND_HUTANG_MODULE") ===
      -1 ? (
        <AksesNotFound />
      ) : (
        <ParameterKadarHutang />
      ),
  },
  {
    path: "/laporan-stock-opname-global",
    exact: true,
    title: "Laporan Stock Opname Global",
    component: () =>
      AppModule.findIndex((list) => list.key === "OPNAME_GLOBAL_DAILY_MODULE") ===
      -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanStockOpnameGlobal />
      ),
  },
  {
    path: "/validasi-susut",
    exact: true,
    title: "Validasi Susut",
    component: () => <ValidasiSusut />,
  },
  {
    path: "/laporan-bayar-point",
    exact: true,
    title: "Laporan Bayar Point",
    component: () =>
      AppModule.findIndex((list) => list.key === "PENJUALAN_BAYAR_POIN_MODULE") ===
      -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanBayarPoint />
      ),
  },
  {
    path: "/laporan-cash-back",
    exact: true,
    title: "Laporan Penjualan Global Perjenis",
    component: () => <LaporanBayarCashback />,
  },
  {
    path: "/laporan-penjualan-global-perjenis",
    exact: true,
    title: "Laporan Uang Kembali",
    component: () => <LaporanPenjualanGlobalJenis />,
  },
  {
    path: "/laporan-global-penjualan-gajah",
    exact: true,
    title: "Laporan Global Penjualan",
    component: () => <FormLapGlobalPenjualanGajah />,
  },

  {
    path: "/laporan-stock-opname",
    exact: true,
    title: "Laporan Stock Opname",
    component: () => <LaporanStockOpname />,
  },

  {
    path: "/laporan-batal-titip",
    exact: true,
    title: "Laporan Batal Titip",
    component: () => <FormLaporanBataltitip />,
  },

  {
    path: "/master-parameter-keterangan-nota",
    exact: true,
    title: "Parameter Keterangan Nota",
    component: () => <ParameterKeteranganNota />,
  },

  {
    path: "/master-supplier",
    exact: true,
    title: "Master Supplier",
    component: () =>
      AppModule.findIndex((list) => list.key === "SUPPLIER_BARANG_MODULE") ===
      -1 ? (
        <AksesNotFound />
      ) : (
        <DataSupllier />
      ),
  },
  {
    path: "/master-plafond-hutang",
    exact: true,
    title: "Plafond Hutang",
    component: () =>
      AppModule.findIndex((list) => list.key === "PLAFOND_HUTANG_MODULE") ===
      -1 ? (
        <AksesNotFound />
      ) : (
        <PlafondHutang />
      ),
  },
  //Module Sepuhan
  {
    path: "/parameter-bonus-sales",
    exact: true,
    title: "Parameter Bonus Sales",
    component: () =>
      AppModule.findIndex(
        (list) => list.key === "BONUS_SALES_TRANSACTION_MODULE"
      ) === -1 ? (
        <AksesNotFound />
      ) : (
        <ParameterBonusSales />
      ),
  },

  {
    path: "/terima-barang-cuci",
    exact: true,
    title: "Terima Barang Cuci",
    component: () =>
      findModule("SEPUHAN_MODULE") &&
      findModule("SEPUHAN_TERPUSAT_MODULE") === false ? (
        <TerimaBarangCuci />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/laporan-kirim-barang-kepusat",
    exact: true,
    title: "Laporan Kirim Barang Ke Pusat",
    component: () =>
      AppModule.findIndex((list) => list.key === "SEPUHAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanKirimBarangKepusat />
      ),
  },
  {
    path: "/lihat-pre-order",
    exact: true,
    title: "Lihat Pre Order",
    component: () =>
      findModule("PRE_ORDER_MODULE") ? <LihatPreOrder /> : <AksesNotFound />,
  },

  {
    path: "/transaksi-pre-order",
    exact: true,
    title: "Transaksi Pre Order",
    component: () =>
      findModule("PRE_ORDER_MODULE") ? <TransakiPreOrder /> : <AksesNotFound />,
  },
  {
    path: "/laporan-penjualan-po",
    exact: true,
    title: "Laporan Penjulan Po",
    component: () =>
      findModule("PRE_ORDER_MODULE") ? (
        <LaporanPenjualanPo />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/closing-barang-po",
    exact: true,
    title: "Closing Barang Po",
    component: () =>
      findModule("PRE_ORDER_MODULE") ? <ClosingPo /> : <AksesNotFound />,
  },
  {
    path: "/laporan-outstand-po",
    exact: true,
    title: "Laporan Outstand Po",
    component: () =>
      findModule("PRE_ORDER_MODULE") ? (
        <LaporanOutstandPo />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/laporan-closing-barang-po",
    exact: true,
    title: "Laporan Closing Po",
    component: () =>
      findModule("PRE_ORDER_MODULE") ? (
        <LaporanClosingBarangPo />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/laporan-transaksi-po",
    exact: true,
    title: "Laporan Transaksi Po",
    component: () =>
      findModule("PRE_ORDER_MODULE") ? (
        <LaporanTransaksiGlobalPo />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/import-pre-order",
    exact: true,
    title: "Import Pre Order",
    component: () =>
      findModule("PRE_ORDER_MODULE") ? <ImportPreOrder /> : <AksesNotFound />,
  },
  {
    path: "/kirim-barang-cuci",
    exact: true,
    title: "Kirim Barang Cuci",
    component: () =>
      findModule("SEPUHAN_MODULE") ? <KirimBarangCuci /> : <AksesNotFound />,
  },
  {
    path: "/stock-cuci-manual",
    exact: true,
    title: "Stock Cuci Manual",
    component: () =>
      findModule("STOCK_SEPUHAN_MANUAL_MODULE") ? (
        <StockSuciManual />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/laporan-stock-cuci-manual",
    exact: true,
    title: "Laporan Stock Cuci Manual",
    component: () =>
      findModule("STOCK_SEPUHAN_MANUAL_MODULE") ? (
        <LaporanStockSepuhanManual />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/sortir-pembelian",
    exact: true,
    title: "Sortir Pembelian",
    component: () =>
      findModule("SEPUHAN_MODULE") &&
      findModule("SEPUHAN_TERPUSAT_MODULE") === false ? (
        <SortirPembelian />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/pindah-sortir",
    exact: true,
    title: "Pindah Sortir",
    component: () =>
      findModule("SEPUHAN_MODULE") &&
      findModule("SEPUHAN_TERPUSAT_MODULE") === false ? (
        <PindahSortir />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/wasting-sepuhan",
    exact: true,
    title: "Wasting Sepuhan",
    component: () =>
      findModule("SEPUHAN_MODULE") &&
      findModule("SEPUHAN_TERPUSAT_MODULE") === false ? (
        <WastingSepuhan />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/whatsapp-setting",
    exact: true,
    title: "Whatsapp Setting",
    component: () =>
      findModule("WHATSAPP_MODULE") ? <WaSetting /> : <AksesNotFound />,
  },
  {
    path: "/export-csv",
    exact: true,
    title: "Export Csv",
    component: () =>
      getItem("userdata").level === "SU" ||
      getItem("userdata").level === "MU" ? (
        <ExportCvs />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/laporan-sortir-pembelian",
    exact: true,
    title: "Laporan Sortir Pembelian",
    component: () =>
      AppModule.findIndex((list) => list.key === "SEPUHAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanSortirPembelian />
      ),
  },
  {
    path: "/laporan-pindah-sortir",
    exact: true,
    title: "Laporan Pindah Sortir",
    component: () =>
      AppModule.findIndex((list) => list.key === "SEPUHAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanPindahSortir />
      ),
  },
  {
    path: "/laporan-saldo-barang-aktif",
    exact: true,
    title: "Laporan Saldo Barang Aktif",
    component: () =>
      AppModule.findIndex((list) => list.key === "SEPUHAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanSaldoBarangAktif />
      ),
  },
  {
    path: "/validasi-pesanan",
    exact: true,
    title: "Validasi Pesanan",
    component: () =>
      findModule("VALIDASI_PESANAN_MODULE") ? (
        <ValidasiPesanan />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/validasi-service",
    exact: true,
    title: "Validasi Service",
    component: () =>
      findModule("VALIDASI_SERVICE_MODULE") ? (
        <ValidasiService />
      ) : (
        <AksesNotFound />
      ),
  },

  //End Module Sepuhan
  {
    path: "/tambah-dan-ambil-stock-aksesoris",
    exact: true,
    title: "Tambah Dan Ambil Stock Aksesoris",
    component: () => <TambahStokAkesoris />,
  },

  {
    path: "/pengalihan-saldo-uang",
    exact: true,
    title: "Pengalihan Saldo Uang",
    component: () => <PengalihanSaldoUang />,
  },
  {
    path: "/master-bank",
    exact: true,
    title: "Master Bank",
    component: () => <MasterBank />,
  },

  {
    path: "/parameter-harga-beli",
    exact: true,
    title: "Master Bank",
    component: () => <ParameterHargaBeli />,
  },

  {
    path: "/faktur-bermasalah",
    exact: true,
    title: "Daftar Faktur Bermasalah",
    component: () => <MasterFakturBermasalah />,
  },
  {
    path: "/master-rekening",
    exact: true,
    title: "Master Rekening",
    component: () => <MasterRekening />,
  },
  {
    path: "/laporan-penjualan-margin",
    exact: true,
    title: "Laporan Penjualan Margin",
    component: () => <LaporanPenjualanMargin />,
  },

  {
    path: "/laporan-terima-barang-supplier",
    exact: true,
    title: "Laporan Terima Barang Supplier",
    component: () => <LaporanTerimaBarangSupplier />,
  },
  {
    path: "/penerimaan-barang",
    exact: true,
    title: "Penerimaan Barang",
    component: () => <PenerimaanBaarang />,
  },
  {
    path: "/batal-penerimaan-barang",
    exact: true,
    title: "Batal Penerimaan Barang",
    component: () => <BatalPenerimaanBarang />,
  },
  //POS AREA
  {
    path: "/home",
    exact: true,
    title: "Dashboard",
    component: () => <PostHomePage />,
  },
  {
    path: "/subMenuBarang",
    exact: true,
    title: "Barang",
    component: () => <SubMenuBarang />,
  },
  {
    path: "/SubMenuPenjualan",
    exact: true,
    title: "Penjualan",
    component: () => <SubMenuPenjualan />,
  },
  {
    path: "/SubMenuPesanan",
    exact: true,
    title: "Pesanan",
    component: () => <SubMenuPesanan />,
  },
  {
    path: "/SubMenuPembelian",
    exact: true,
    title: "Pembelian",
    component: () => <SubMenuPembelian />,
  },
  {
    path: "/SubMenuService",
    exact: true,
    title: "Servic",
    component: () => <SubMenuService />,
  },
  {
    path: "/SubMenuHutang",
    exact: true,
    title: "Hutang",
    component: () => <SubMenuHutang />,
  },
  {
    path: "/SubMenuValidasi",
    exact: true,
    title: "Validasi",
    component: () => <SubMenuValidasi />,
  },
  {
    path: "/SubMenuLaporan",
    exact: true,
    title: "Laporan",
    component: () => <SubMenuLaporan />,
  },
  {
    path: "/SubMenuUtility",
    exact: true,
    title: "Utility",
    component: () => <SubMenuUtility />,
  },
  {
    path: "/SubMenuMember",
    exact: true,
    title: "Utility",
    component: () => <SubMenuMember />,
  },
  //AKHIR POS AREA
  //Dashboard
  {
    path: "/dashboard",
    exact: true,
    title: "Dashboard",
    component: () => <Dashboard />,
  },
  {
    path: "/dashboardPusat",
    exact: true,
    title: "Dashboard",
    component: () => <DashboardV2 />,
  },
  // {
  //   path: "/DasboardPusatPantes",
  //   exact: true,
  //   title: "Dashboard",
  //   component: () => <DasboardPusatPantes />
  // },
  // Data Master
  {
    path: "/master-kategori",
    exact: true,
    title: "Master Kategori",
    component: () => <MasterKategori />,
  },

  // Data Jenis
  {
    path: "/master-jenis",
    exact: true,
    title: "Master Jenis",
    component: () => <MasterJenis />,
  },

  // Data Group
  {
    path: "/master-group",
    exact: true,
    title: "Master Group",
    component: () => <MasterGroup />,
  },

  // Master Bandrol
  {
    path: "/master-bandrol",
    exact: true,
    title: "Master Bandrol",
    component: () => <MasterBandrol />,
  },

  // Master Bandrol
  {
    path: "/master-kode-transaksi",
    exact: true,
    title: "Master Kode Transaksi",
    component: () => <MasterKodeTransaksi />,
  },

  //Master Gudang
  {
    path: "/master-gudang",
    exact: true,
    title: "Master Gudang",
    component: () => <MasterGudang />,
  },

  //Master Baki
  {
    path: "/master-baki",
    exact: true,
    title: "Master Baki",
    component: () => <MasterBaki />,
  },
  //Master Sales
  {
    path: "/master-sales",
    exact: true,
    title: "Master Sales",
    component: () => <MasterSales />,
  },

  //Master Tukang
  {
    path: "/master-tukang",
    exact: true,
    title: "Master Tukang",
    component: () => <MasterTukang />,
  },

  //Master Kondisi Barang
  {
    path: "/master-kondisi-barang",
    exact: true,
    title: "Master Kondisi Barang",
    component: () => <MasterKondisiBarang />,
  },
  {
    path: "/tambah-data-barang",
    exact: true,
    title: "Tambah Data Barang",
    component: () => <TambahBarang />,
  },
  {
    path: "/tambah-barang-banyak",
    exact: true,
    title: "Tambah Barang Banyak",
    component: () => <TambahBarangBanyak />,
  },

  //Master Kondisi Barang
  {
    path: "/master-kondisi-pembelian",
    exact: true,
    title: "Master Kondisi Pembelian",
    component: () => <MasterKondisiPembelian />,
  },

  //Master Kondisi Barang
  {
    path: "/master-kondisi-pinjaman",
    exact: true,
    title: "Master Kondisi Pinjaman",
    component: () => <MasterKondisiPinjaman />,
  },

  //Master Data Hadiah
  {
    path: "/master-data-hadiah",
    exact: true,
    title: "Master Data Hadiah",
    component: () => <MasterHadiah />,
  },

  //Master Data Hadiah
  {
    path: "/master-parameter-rugi-nota",
    exact: true,
    title: "Master Parameter Rugi Nota",
    component: () => <MasterParameterRugiNota />,
  },
  {
    path: "/master-parameter-harga-emas",
    exact: true,
    title: "Master Parameter Rugi Nota",
    component: () => <FormhargaEmas />,
  },
  {
    path: "/master-parameter-jenis-group",
    exact: true,
    title: "Master Parameter Jenis Group",
    component: () => <MasterJenisGroup />,
  },
  {
    path: "/master-kadar",
    exact: true,
    title: "Master Kadar",
    component: () => <MasterKadar />,
  },

  // Master Belian
  {
    path: "/jenis-batu",
    exact: true,
    title: "Jenis Batu",
    component: () =>
      AppModule.findIndex((list) => list.key === "BERLIAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <JenisBatu />
      ),
  },
  {
    path: "/master-supplier-berlian",
    exact: true,
    title: "Master Supplier Berlian",
    component: () =>
      AppModule.findIndex((list) => list.key === "BERLIAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <MasterSupplierBerlian />
      ),
  },
  {
    path: "/laporan-buku-berlian",
    exact: true,
    title: "Laporan Buku Berlian",
    component: () =>
      AppModule.findIndex((list) => list.key === "BERLIAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <BukiBerlian />
      ),
  },
  {
    path: "/master-batu-shape",
    exact: true,
    title: "Batu Shape",
    component: () =>
      AppModule.findIndex((list) => list.key === "BERLIAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <BatuShape />
      ),
  },
  {
    path: "/master-batu-color",
    exact: true,
    title: "Batu Color",
    component: () =>
      AppModule.findIndex((list) => list.key === "BERLIAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <BatuColor />
      ),
  },
  {
    path: "/master-batu-clarity",
    exact: true,
    title: "Batu Clarity",
    component: () =>
      AppModule.findIndex((list) => list.key === "BERLIAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <BatuClarity />
      ),
  },
  {
    path: "/master-batu-fluorencence",
    exact: true,
    title: "Batu Fluorencence",
    component: () =>
      AppModule.findIndex((list) => list.key === "BERLIAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <BatuFluorencence />
      ),
  },
  {
    path: "/master-batu-symetry",
    exact: true,
    title: "Batu Fluorencence",
    component: () =>
      AppModule.findIndex((list) => list.key === "BERLIAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <BatuSymetry />
      ),
  },
  {
    path: "/master-batu-polish",
    exact: true,
    title: "Batu Polish",
    component: () =>
      AppModule.findIndex((list) => list.key === "BERLIAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <BatuPolish />
      ),
  },
  {
    path: "/master-batu-cutgrade",
    exact: true,
    title: "Batu Cutgrade",
    component: () =>
      AppModule.findIndex((list) => list.key === "BERLIAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <BatuCutgrade />
      ),
  },
  {
    path: "/master-batu-berlian",
    exact: true,
    title: "Batu Berlian",
    component: () =>
      AppModule.findIndex((list) => list.key === "BERLIAN_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <BatuBerlian />
      ),
  },

  {
    path: "/master-kadar-eod",
    exact: true,
    title: "Master Kadar Eod",
    component: () => <MasterKadarEod />,
  },
  {
    path: "/master-kota",
    exact: true,
    title: "Master Kota",
    component: () => <MasterKota />,
  },
  {
    path: "/master-kecamatan",
    exact: true,
    title: "Master Kecamatan",
    component: () => <MasterKecamatan />,
  },

  //End Data Master Berlian

  //Transaksi pembelian
  {
    path: "/transaksi-pembelian",
    exact: true,
    title: "Transaksi Pembelian",
    component: () => <FormTransaksiPembelian />,
  },
  {
    path: "/hancur-pembelian",
    exact: true,
    title: "Hancur Pembelian",
    component: () => <HancurBeli />,
  },
  {
    path: "/laporan-hancur-pembelian",
    exact: true,
    title: "Laporan Hancur Pembelian",
    component: () => <LaporanHancurPembelian />,
  },
  {
    path: "/lihat-pembelian",
    exact: true,
    title: "Lihat Pembelian",
    component: () => <FormLihatPembelian />,
  },
  {
    path: "/batal-pembelian",
    exact: true,
    title: "Batal Pembelian",
    component: () => <FormBatalPembelian />,
  },
  {
    path: "/laporan-pembelian-sales",
    exact: true,
    title: "Laporan Pembelian Sales",
    component: () => <FormLaporanPembelianSales />,
  },

  {
    path: "/laporan-transaksi-pembelian",
    exact: true,
    title: "Laporan Transaksi Pembelian",
    // component: () => <FormLaporanPembelianBarang />
    component: () => <IndexLaporanPembelian />,
  },
  {
    path: "/laporan-rekap-transaksi-pembelian",
    exact: true,
    title: "Laporan Rekap Transaksi Pembelian",
    component: () => (tokoBerkah ? <IndexLaporanPembelianRekapBerkah /> : null),
  },
  {
    path: "/laporan-transaksi-pembelian-history",
    exact: true,
    title: "Laporan Transaksi Pembelian History",
    component: () => <FormLaporanPembelianBarangHistory />,
  },
  {
    path: "/laporan-batal-pembelian",
    exact: true,
    title: "Laporan Batal Pembelian",
    component: () => <FormLaporanBatalBeli />,
  },
  {
    path: "/master-parameter-point",
    exact: true,
    title: "Parameter Poin",
    component: () => <FormParamterPoint />,
  },

  //Transaksi Penjualan
  {
    path: "/transaksi-penjualan",
    exact: true,
    title: "Transaksi Penjualan",
    component: () => <FormTransaksiPenjualan />,
  },
  {
    path: "/lihat-penjualan",
    exact: true,
    title: "Lihat Penjualan",
    component: () => <FormLihatPenjualan />,
  },
  {
    path: "/validasi-penjualan",
    exact: true,
    title: "Validasi Penjualan",
    component: () => <FormValidasiPenjualan />,
  },
  {
    path: "/validasi-titipan",
    exact: true,
    title: "Validasi titipan",
    component: () => <FormValidasiTitipan />,
  },
  {
    path: "/batal-penjualan",
    exact: true,
    title: "Batal Penjualan",
    component: () => <FormBatalPenjualan />,
  },
  {
    path: "/lihat-titipan",
    exact: true,
    title: "Lihat Titipan",
    component: () => <BatalTitipan />,
  },
  {
    path: "/laporan-batal-penjualan",
    exact: true,
    title: "Laporan Batal Penjualan",
    component: () => <FormLaporanBatalPenjualan />,
  },
  {
    path: "/transaksi-pesanan",
    exact: true,
    title: "Transaksi Pesanan",
    component: () => <Pesanan />,
  },
  {
    path: "/ambil-barang-contoh",
    exact: true,
    title: "Ambil Pesanan",
    component: () => <AmbilBarangContoh />,
  },
  {
    path: "/lihat-pesanan",
    exact: true,
    title: "Lihat Pesanan",
    component: () => <LihatPesanan />,
  },
  {
    path: "/laporan-titip-uang",
    exact: true,
    title: "Laporan Titip Uang",
    component: () => <LaporanTitipUang />,
  },
  {
    path: "/laporan-pesanan",
    exact: true,
    title: "Laporan Pesanan",
    component: () => <LaporanPesanan />,
  },
  {
    path: "/laporan-pesanan-barang",
    exact: true,
    title: "Laporan Pesanan Barang",
    component: () => <LaporanPesananBarang />,
  },
  {
    path: "/laporan-batal-pesanan",
    exact: true,
    title: "Laporan Batal Pesanan",
    component: () => <LaporanBatalPesanan />,
  },
  {
    path: "/uang-bank",
    exact: true,
    title: "Uang Bank",
    component: () => <UangBank />,
  },
  {
    path: "/gesek-tunai",
    exact: true,
    title: "Gesek Tunai",
    component: () => <GesekTunai />,
  },
  {
    path: "/mutasi-uang-bank",
    exact: true,
    title: "Mutasi Uang Bank",
    component: () => <MutasiUangBank />,
  },
  {
    path: "/mutasi-uang-cash",
    exact: true,
    title: "Mutasi Uang Cash",
    component: () => <MutasiUangCash />,
  },
  {
    path: "/laporan-gesek-tunai",
    exact: true,
    title: "Laporan Gesek Tunai",
    component: () => <LaporanGesekTunai />,
  },

  {
    path: "/laporan-perubahan-harga-emas",
    exact: true,
    title: "Laporan Perubahan Harga Emas",
    component: () => <PerubahanHargaEmas />,
  },
  {
    path: "/laporan-penjualan-sales",
    exact: true,
    title: "Laporan Penjualan Sales",
    component: () => <LaporanPenjualanSales />,
  },
  {
    path: "/laporan-jual-beli-sales-manual",
    exact: true,
    title: "Laporan Jual Beli Sales Manual",
    component: () => <LaporanJualBeliSalesManual />,
  },
  {
    path: "/laporan-transaksi-penjualan",
    exact: true,
    title: "Laporan Transaksi Penjualan",
    component: () => <FormLaporanPenjualanBarang />,
  },
  {
    path: "/laporan-transaksi-penjuala-berlian",
    exact: true,
    title: "Laporan Transaksi Penjualan Berlian",
    component: () => <FormLaporanPenjualanBerlian />,
  },

  {
    path: "/laporan-penjualan-cicilan",
    exact: true,
    title: "Laporan Penjualan Cicilan",
    component: () => <FormLaporanPenjualanCicilan />,
  },
  {
    path: "/laporan-penjualan-bayar",
    exact: true,
    title: "Laporan Penjualan Bayar",
    component: () => <FormLaporanPenjualanBayar />,
  },

  //Manage Data
  {
    path: "/import-data-pusat",
    exact: true,
    title: "Import Data Pusat",
    component: () => <ImportDataPusat />,
  },

  //Module Kirim Barang
  {
    path: "/laporan-kirim-barang-online",
    exact: true,
    title: "Laporan Kirim Barang Online",
    component: () =>
      AppModule.findIndex(
        (list) => list.key === "KIRIM_BARANG_ONLINE_STORE_MODULE"
      ) === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanKirimBarangOnline />
      ),
  },
  {
    path: "/kirim-barang-kecabang",
    exact: true,
    title: "Kirim Barang Kecabang",
    component: () =>
      AppModule.findIndex((list) => list.key === "KIRIM_BARANG_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <KirimDataBarang />
      ),
  },

  {
    path: "/tutup-toko",
    exact: true,
    title: "Tutup Toko",
    component: () => <TutupToko />,
  },
  {
    path: "/laporan-kirim-barang",
    exact: true,
    title: "Kirim Kirim Barang",
    component: () =>
      AppModule.findIndex((list) => list.key === "KIRIM_BARANG_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanKirimBarang />
      ),
  },
  {
    path: "/terima-barang",
    exact: true,
    title: "Terima Barang",
    component: () =>
      AppModule.findIndex((list) => list.key === "KIRIM_BARANG_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <TerimaBarang />
      ),
  },
  {
    path: "/master-toko",
    exact: true,
    title: "Master Toko",
    component: () =>
      AppModule.findIndex((list) => list.key === "KIRIM_BARANG_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <MasterToko />
      ),
  },

  {
    path: "/kirim-barang",
    exact: true,
    title: "Kirim Barang",
    component: () =>
      AppModule.findIndex((list) => list.key === "KIRIM_BARANG_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <KirimBarang />
      ),
  },
  {
    path: "/master-potongan-pembelian",
    exact: true,
    title: "Potongan Penjualan",
    component: () =>
      AppModule.findIndex((list) => list.key === "POTONGAN_PENJUALAN_MODULE") ===
      -1 ? (
        <AksesNotFound />
      ) : (
        <PotonganPenjualan />
      ),
  },
  {
    path: "/parameter-kupon",
    exact: true,
    title: "Parameter Kupon",
    component: () =>
      AppModule.findIndex((list) => list.key === "KUPON_PENJUALAN_MODULE") ===
      -1 ? (
        <AksesNotFound />
      ) : (
        <ParameterKupon />
      ),
  },
  {
    path: "/cek-kupon",
    exact: true,
    title: "Cek Kupon",
    component: () =>
      AppModule.findIndex((list) => list.key === "KUPON_PENJUALAN_MODULE") ===
      -1 ? (
        <AksesNotFound />
      ) : (
        <CekKuponPage />
      ),
  },

  //Services
  {
    path: "/transaksi-services",
    exact: true,
    title: "Transaksi Services",
    component: () => <FormTransaksiServces />,
  },
  {
    path: "/lihat-services",
    exact: true,
    title: "Lihat Services",
    component: () => <LihatServices />,
  },
  {
    path: "/laporan-services",
    exact: true,
    title: "Laporan Services",
    component: () => <FormLaporanService />,
  },
  {
    path: "/laporan-stock-hutang",
    exact: true,
    title: "Laporan Stock Hutang",
    component: () => <LaporanStockHutang />,
  },
  {
    path: "/laporan-batal-service",
    exact: true,
    title: "Laporan Batal Services",
    component: () => <FormLaporanBatalService />,
  },
  {
    path: "/laporan-service-ambil",
    exact: true,
    title: "Laporan Serice Ambil",
    component: () => <FormLaporanServiceAmbil />,
  },

  //Manage Users
  {
    path: "/data-users",
    exact: true,
    title: "Data Users",
    component: () => <DataUsers />,
  },
  {
    path: "/hak-akses-users",
    exact: true,
    title: "Hak Akses Users",
    component: () => <FormHakAkses />,
  },

  //Barang
  {
    path: "/data-barang",
    exact: true,
    title: "Data Barang",
    component: () => <DataBarang />,
  },

  {
    path: "/pindah-barang",
    exact: true,
    title: "Pindah Barang",
    component: () => <FormPindahBarang />,
  },

  //Hancur Barang
  {
    path: "/hancur-data-barang",
    exact: true,
    title: "Hancur Data Barang",
    component: () => <FormHancurBarang />,
  },

  //Hancur Barang PSU
  {
    path: "/hancur-data-barang-psu",
    exact: true,
    title: "Hancur Data Barang PSU",
    component: () => <FormHancurBarangPSU />,
  },

  //Pindah Barang Satu Baki
  {
    path: "/pindah-barang-satu-baki",
    exact: true,
    title: "Pindah Barang Satu Baki",
    component: () => <FormPindahBarangSatuBaki />,
  },

  //Pindah Barang Satu Baki
  {
    path: "/timbang-baki",
    exact: true,
    title: "Timbang barang",
    component: () => <TimbangBarang />,
  },

  //Transaksi Hutang
  {
    path: "/transaksi-hutang",
    exact: true,
    title: "Transaksi Hutang",
    component: () => <FormTransaksiHutang />,
  },
  {
    path: "/lihat-hutang",
    exact: true,
    title: "Lihat Hutang",
    component: () => <FormLihatHutang />,
  },
  {
    path: "/lelang-hutang",
    exact: true,
    title: "Lelang Hutang",
    component: () => <FormLelangHutang />,
  },
  {
    path: "/batal-hutang-lunas",
    exact: true,
    title: "Batal Hutang Lunas",
    component: () => <BatalHutangLunas />,
  },
  {
    path: "/validasi-hutang",
    exact: true,
    title: "Validasi Hutang",
    component: () => <FormValidasiHutang />,
  },
  {
    path: "/validasi-jual-beli",
    exact: true,
    title: "Validasi Jual",
    component: () => <FormValidasiJualBeli />,
  },
  {
    path: "/validasi-beli",
    exact: true,
    title: "Validasi Beli",
    component: () => <FormValidasiBeli />,
  },
  {
    path: "/validasi-jual-beli-manual",
    exact: true,
    title: "Validasi Jual Beli Manual",
    component: () =>
      findModule("MANUAL_ITEMS_TRANSACTION_MODULE") ? (
        <FormValidasiJualBeliManual />
      ) : (
        <AksesNotFound />
      ),
  },

  //Uang Kas
  // {
  //   path: "/tambah-uang-kas",
  //   exact: true,
  //   title: "Tambah Uang Kas",
  //   component: () => <TambahUangKas />,
  // },
  // {
  //   path: "/ambil-uang-kas",
  //   exact: true,
  //   title: "Ambil Uang Kas",
  //   component: () => <AmbilUangKas />,
  // },

  // Laporan

  // Laporan Keuangan
  {
    path: "/laporan-transaksi-keuangan-cash",
    exact: true,
    title: "Laporan Transaksi Keuangan Cash",
    component: () => <LaporanKeuanganKas />,
  },
  {
    path: "/laporan-kirim-uang-pusat",
    exact: true,
    title: "Laporan Kirim Uang",
    component: () => <LaporanKirimUangPusat />,
  },
  {
    path: "/laporan-terima-uang-pusat",
    exact: true,
    title: "Laporan Terima Uang Pusat",
    component: () => <LaporanTerimaUangPusat />,
  },
  {
    path: "/laporan-keuangan-non-cash",
    exact: true,
    title: "Laporan Keuangan Non Cash",
    component: () => <LaporanNonCash />,
  },

  //Laporan Terima Barang
  {
    path: "/laporan-terima-barang",
    exact: true,
    title: "Laporan Terima Barang",
    component: () => <FormLaporanTerimaBarang />,
  },
  {
    path: "/laporan-otorisasi",
    exact: true,
    title: "Laporan Otorisasi",
    component: () => <LaporanOtorisasi />,
  },
  //Laporan Pindah Barang
  {
    path: "/laporan-pindah-barang",
    exact: true,
    title: "Laporan Pindah Barang",
    component: () => <FormLaporanPindahBarang />,
  },
  //Laporan Hancur Barang
  {
    path: "/laporan-hancur-barang",
    exact: true,
    title: "Laporan Hancur Barang",
    component: () => <FormLaporanHancurBarang />,
  },

  //Laporan Detail Barang
  {
    path: "/laporan-barang-detail",
    exact: true,
    title: "Laporan Barang Detail",
    component: () => <FormDetailBarang />,
  },
  {
    path: "/laporan-pembelian-per-kondisi",
    exact: true,
    title: "Laporan Pembelian Per Kondisi",
    component: () => <FormLaporanPembelianPerKondisi />,
  },
  {
    path: "/laporan-akhir_hari",
    exact: true,
    title: "Laporan Akhir Hari",
    component: () => <FormLaporanAkhirHari />,
  },
  {
    path: "/laporan-keuangan-akhir-hari",
    exact: true,
    title: "Laporan Keuangan Akhir Hari",
    component: () => <FormLaporanKeuanganAkhirHari />,
  },
  {
    path: "/laporan-harian-emas",
    exact: true,
    title: "Laporan Harian Emas",
    component: () => <FormHarianEmas />,
  },
  {
    path: "/laporan-kelompok-barang",
    exact: true,
    title: "Laporan Kelompok Barang",
    component: () => <LaporanKelompokBarang />,
  },
  {
    path: "/laporan-barang-sumary",
    exact: true,
    title: "Laporan Barang Sumary",
    component: () => <FormLaporanBarangSumary />,
  },
  {
    path: "/laporan-barang-sumary-periode",
    exact: true,
    title: "Laporan Barang Sumary Periode",
    component: () => <FormLaporanBarangSumaryPeriode />,
  },
  {
    path: "/laporan-analisis-harga-barang",
    exact: true,
    title: "Laporan Analisi Harga Barang",
    component: () => <FormLaporanAnalisisHargaBarang />,
  },
  {
    path: "/laporan-penjualan-marketplace",
    exact: true,
    title: "Laporan Penjualan Marketplace",
    component: () => <LaporanPenjualanMarketPlace />,
  },
  {
    path: "/laporan-stock-jual",
    exact: true,
    title: "Laporan Stock Jual",
    component: () => <LaporanStockJual />,
  },
  {
    path: "/laporan-service-masuk",
    exact: true,
    title: "Laporan Service Masuk",
    component: () => <LaporanServiceMasuk />,
  },
  {
    path: "/laporan-service-selesai",
    exact: true,
    title: "Laporan Service Selesai",
    component: () => <LaporanServiceSelesai />,
  },

  {
    path: "/laporan-tambah-barang",
    exact: true,
    title: "Laporan Tambah Barang",
    component: () => <FormLaporanTambahBarang />,
  },

  {
    path: "/setting-nota-slip",
    exact: true,
    title: "Setting Nota",
    // component: () => <SettingNotaSlip />,
    component: () =>
      findModule("SETTING_NOTA_OWNER_ONLY") ? (
        getUserdata().level === "OWN" || getUserdata().level === "SU" ? (
          <SettingNotaSlip />
        ) : (
          <AksesNotFound />
        )
      ) : (
        <SettingNotaSlip />
      ),
  },
  {
    path: "/stock-opname-rfid",
    exact: true,
    title: "Stock Opname Rfid",
    component: () =>
      AppModule.findIndex((list) => list.key === "RFID_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <StockOpnameRfid />
      ),
  },
  {
    path: "/laporan-stock-opname-rfid",
    exact: true,
    title: "Laporan Stock Opname Rfid",
    component: () =>
      AppModule.findIndex((list) => list.key === "RFID_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanStockOpnameRfid />
      ),
  },

  {
    path: "/settingsystem",
    exact: true,
    title: "Setting System",
    component: () =>
      getUserdata().level === "OWN" ||
      getUserdata().level === "SU" ||
      getUserdata().level === "DEV" ||
      getUserdata().level === "MU" ? (
        <SettingSystem />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/cetak-barcode",
    exact: true,
    title: "Cetak Barcode",
    component: () => <CetakBarcode />,
  },
  //Module Member
  {
    path: "/data-member",
    exact: true,
    title: "Data Member",
    component: () =>
      AppModule.findIndex((list) => list.key === "MEMBER_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <DataMember />
      ),
  },
  {
    path: "/setting-member",
    exact: true,
    title: "Setting Member",
    component: () =>
      AppModule.findIndex((list) => list.key === "MEMBER_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <SettingMember />
      ),
  },
  {
    path: "/lihat-transaksi-member",
    exact: true,
    title: "Lihat Transaksi Member",
    component: () =>
      AppModule.findIndex((list) => list.key === "MEMBER_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LihatTransaksiMember />
      ),
  },
  {
    path: "/transaksi-poin",
    exact: true,
    title: "Transaksi Poin",
    component: () =>
      AppModule.findIndex((list) => list.key === "MEMBER_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <FormTransaksiPoin />
      ),
  },
  {
    path: "/tukar-point",
    exact: true,
    title: "Tukar Poin",
    component: () =>
      AppModule.findIndex((list) => list.key === "MEMBER_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <FormTukarPoin />
      ),
  },
  {
    path: "/laporan-daftar-member",
    exact: true,
    title: "Laporan daftar Member",
    component: () =>
      AppModule.findIndex((list) => list.key === "MEMBER_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <FormLaporanMember />
      ),
  },
  {
    path: "/laporan-top-member",
    exact: true,
    title: "Laporan Top Member",
    component: () =>
      AppModule.findIndex((list) => list.key === "MEMBER_MODULE") === -1 ? (
        <AksesNotFound />
      ) : (
        <LaporanTopMember />
      ),
  },

  //Module Member
  {
    path: "/laporan-transaksi-hutang",
    exact: true,
    title: "Laporan Transaksi Hutang",
    component: () => <FormLaporanHutang />,
  },
  {
    path: "/laporan-hutang-lunas",
    exact: true,
    title: "Laporan Hutang Lunas",
    component: () => <FormLaporanHutangLunas />,
  },
  {
    path: "/laporan-pinjaman-detail",
    exact: true,
    title: "Laporan Pinjaman Detail",
    component: () => <LaporanHutangDetail />,
  },
  {
    path: "/laporan-batal-hutang",
    exact: true,
    title: "Laporan Batal Hutang",
    component: () => <FormLaporanBatalHutang />,
  },
  {
    path: "/laporan-lelang-hutang",
    exact: true,
    title: "Laporan Batal Hutang",
    component: () => <FormLaporanLelangHutang />,
  },
  {
    path: "/stock-opname",
    exact: true,
    title: "Stock Opname",
    component: () => <FormStockOpname />,
  },

  {
    path: "/stock-opname-penerimaan",
    exact: true,
    title: "Stock Opname Penerimaan",
    component: () => <StockOpnamePenerimaan />,
  },
  {
    path: "/uang-kas",
    exact: true,
    title: "Lihat Uang kas",
    component: () => <LihatUangKas />,
  },
  {
    path: "/ganti-password",
    exact: true,
    title: "Ganti Password",
    component: () => <GantiPassword />,
  },
  {
    path: "/kirim-uang-pusat",
    exact: true,
    title: "Kirim Uang",
    component: () => <KirimUangPusat />,
  },
  {
    path: "/terima-uang-kas",
    exact: true,
    title: "Terima Uang kas",
    component: () => <TerimaUangPusat />,
  },
  {
    path: "/laporan-eod",
    exact: true,
    title: "Laporan Eod",
    component: () => <IndexLaporanEod />,
  },
  {
    path: "/laporan-managerial-analisys",
    exact: true,
    title: "Laporan Managerial Analisys",
    component: () => <LaporanManagerialAnalisys />,
  },
  {
    path: "/validasi-non-cash",
    exact: true,
    title: "Validasi Non Cash",
    component: () =>
      findModule("VALIDASI_NON_CASH_MODULE") ? (
        <ValidasiNonCash />
      ) : (
        <PageNotFound />
      ),
  },
  {
    path: "/update-profit-barang",
    exact: true,
    title: "Update Profit",
    component: () => <UpdatePfrofit />,
  },
  {
    path: "/laporan-authorization",
    exact: true,
    title: "Laporan Eod",
    component: () => <LaporanOtorisasi />,
  },

  {
    path: "/laporan-timbang-baki",
    exact: true,
    title: "Laporan Timbang Baki",
    component: () => <FormLaporanTimbangBarang />,
  },

  {
    path: "/laporan-barang-tukar",
    exact: true,
    title: "Laporan Barang Tukar",
    component: () => <FormLaporanPenjualanTukar />,
  },
  {
    path: "/laporan-pembelian-transfer",
    exact: true,
    title: "Laporan Pembelian Transfer",
    component: () => <LaporanPembelianTransfer />,
  },
  {
    path: "/laporan-batal-tukar",
    exact: true,
    title: "Laporan Batal Tukar",
    component: () => <LaporanBatalTukar />,
  },
  {
    path: "/laporan-stock-beli",
    exact: true,
    title: "Laporan Stock Beli",
    component: () => <LaporanStockBeli />,
  },

  {
    path: "/laporan-global-transaksi",
    exact: true,
    title: "Laporan Global Transaksi",
    component: () => <LaporanGlobalTransaksi />,
  },
  {
    path: "/settingtoko",
    exact: true,
    title: "Setting Toko",
    component: () => <SettingToko />,
  },
  {
    path: "/hak-akses-news",
    exact: true,
    title: "Hak Akses News",
    component: () => <HakAksesNews />,
  },
  {
    path: "/master-marketplace",
    exact: true,
    title: "Master Marketplace",
    component: () => <DataMareketPlace />,
  },
  {
    path: "/laporan-penjualan-rekap",
    exact: true,
    title: "Laporan Rekap Jual (For Tax Purpose)",
    component: () => <LaporanPenjualanTaxPurpose />,
  },
  {
    path: "/redirect-to-grosir",
    exact: true,
    title: "Grosir",
    component: () =>
      findModule("SEMI_GROSIR_MODULE") ? (
        <RedirectForm />
      ) : (
        <AksesTidakDiizinkan />
      ),
  },
  {
    path: "/blank",
    exact: true,
    title: "blank",
    component: () => <BlankPages />,
  },
  {
    path: "/landingpages",
    exact: true,
    title: "blank",
    component: () => <> Landing Pages </>,
  },
  {
    path: "/404",
    exact: true,
    title: "Halaman Tidak Ditemuka",
    component: () => <PageError />,
  },
  {
    path: "/parameter-deskripsi-service",
    exact: true,
    title: "Parameter Deskripsi Service",
    component: () =>
      AppModule.findIndex(
        (list) => list.key === "PARAMETER_DESKRIPSI_SERVICE_MODULE"
      ) === -1 ? (
        <AksesNotFound />
      ) : (
        <ParameterDeskripsiService />
      ),
  },
  {
    path: "/parameter-akun",
    exact: true,
    title: "Parameter Akun",
    component: () =>
      findModule("NAGAFIN_ACCOUNTING_MODULE") ? (
        <ParameterAkun />
      ) : (
        <AksesNotFound />
      ),
  },
  {
    path: "/kategori-katalog",
    exact: true,
    title: "Kategori Katalog",
    component: () => <KategoriKatalog />,
  },
  {
    path: "/harga-katalog",
    exact: true,
    title: "Harga Katalog",
    component: () => <HargaKatalog />,
  },
  {
    path: "/banner-katalog",
    exact: true,
    title: "Banner Katalog",
    component: () => <BannerKatalog />,
  },
  {
    path: "/info-katalog",
    exact: true,
    title: "Info Katalog",
    component: () => <InfoKatalog />,
  },
  {
    path: "/item-katalog",
    exact: true,
    title: "Barang Katalog",
    component: () => <BarangKatalog />,
  },
  {
    path: "/kontak-admin-katalog",
    exact: true,
    title: "Kontak Admin Katalog",
    component: () => <KontakAdminKatalog />,
  },
  {
    path: "/hadiah-katalog",
    exact: true,
    title: "Hadiah Katalog",
    component: () => <HadiahKatalog />,
  },
  {
    path: "/group-description-katalog",
    exact: true,
    title: "Deskripsi Group",
    component: () => <GroupDescriptionKatalog />,
  },
  {
    path: "/splashscreen-katalog",
    exact: true,
    title: "Splashscreen Katalog",
    component: () => <SplashscreenKatalog />,
  },
];

export default routes;
